import lodash from 'lodash';
import React, { Component } from 'react';
import {
  Modal,
  Segment,
  Button,
  Grid,
  Label,
  Form,
  Message,
  Image,
  List
} from 'semantic-ui-react';
import logo from '../../assets/ChivvyLogoPrimary-500.svg';
import icon from '../../assets/Icon-Blue.jpg';
import API from '../../services/API';
import './userRegistrationStyles.css';

export default class UserRegistration extends Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    orgName: '',
    isEventProducer: '',
    formIncomplete: false,
    passwordMismatch: false,
    registerSuccessModalOpen: false,
    emailMisformatted: false,
    emailExists: false,
    groupExists: false,
    hasError: false,
    validationErrors: {},
  };

  handleFieldChange = (name, e, { value }) => {
    const newValidationErrors = {...this.state.validationErrors};
    delete newValidationErrors[name];

    if (name === 'lastName' || name === 'firstName') {
      delete newValidationErrors.name;
    }

    this.setState({
      [name]: value,
      formIncomplete: false,
      hasError: (lodash.size(newValidationErrors) > 0),
      validationErrors: newValidationErrors,
    });
  };

  clearWarnings = () => {
    this.setState({
      formIncomplete: false,
      passwordMismatch: false,
      emailMisformatted: false,
      groupExists: false,
      emailExists: false,
      hasError: false,
      validationErrors: {},
    }, this.registerUser);
  }

  closeModal = () => {
    this.setState({
      registerSuccessModalOpen: false
    });

    window.location.href = "/login";
  }

  registerUser = () => {
    let {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      orgName,
      isEventProducer,
    } = this.state;

    email = email.trim().toLowerCase();
    firstName = firstName.trim();
    lastName = lastName.trim();
    password = password.trim();
    orgName = orgName.replace(/ /g, '');

    if (password !== confirmPassword) {
      this.setState({
        passwordMismatch: true,
        hasError: true,
      });
    // } else if (users.findIndex(p => p.email.toLowerCase() == email) > -1) {
    //   this.setState({
    //     emailExists: true
    //   });
    } else if (!firstName.length || !lastName.length || !password.length || !confirmPassword.length || !orgName.length || !isEventProducer.length) {
      this.setState({
        formIncomplete: true
      });
    } else if (email.indexOf('@') < 0 || email.indexOf('.') < 0) {
      this.setState({
        emailMisformatted: true,
        hasError: true,
      });
    } else {
      let newUser = {
        'email': email,
        'firstName': firstName,
        'lastName': lastName,
        'password': password,
        'confirmPassword': confirmPassword,
        'orgName': orgName,
        'isEventProducer': isEventProducer
      };

      this.pushNewUser(newUser);
    }
  };

  pushNewUser = async (user) => {
    const createClientResponse = await API.post('/auth/register/client', {
      client: {orgName: user.orgName}
    });

    if (!lodash.get(createClientResponse, 'data.success')) {
      this.setState({
        hasError: true,
      });
      return;
    }

    const newUser = {
      "name": `${user.firstName} ${user.lastName}`,
      "email": user.email,
      "password": user.password,
      "isEventProducer": user.isEventProducer,
      "firstName": user.firstName,
      "lastName": user.lastName,
      "orgName": user.orgName
    };

    let createUserResponse;
    try {
      createUserResponse = await API.post('/auth/register', {
        clientId: createClientResponse.data.content,
        user: newUser,
        groupName: user.orgName,
      });
    } catch (registerUserError) {
      this.setState({
        hasError: true,
      });
      return;
    }

    if (!lodash.get(createUserResponse, 'data.success')) {
      const responseData = createUserResponse.data || {};
      const fieldErrors = responseData.fieldErrors || {};

      if (responseData.cognitoGroupFail) {
        this.setState({
          groupExists: true,
          hasError: true,
          validationErrors: fieldErrors,
        });
      } else if (responseData.cognitoUserFail) {
        this.setState({
          hasError: true,
          validationErrors: fieldErrors,
        });
      } else if (responseData.cognitoUserGroupFail) {
        this.setState({
          hasError: true,
          validationErrors: fieldErrors,
        });
      }

      return;
    }

    this.setState({
      registerSuccessModalOpen: true,
      hasError: false,
      validationErrors: {},
    });
  }

  render() {
    const {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      orgName,
      isEventProducer
    } = this.state;

    const accountTypeOpts = [
      {key:'0', text:'Curator', value: '0'},
      {key:'1', text:'Provider', value: '1'}
    ];

    const errorsMap = this.state.validationErrors || {};
    const hasFieldErrors = (lodash.size(errorsMap) > 0);

    return (
      <div className='registrationContainer'>
        <Grid>
          <Grid.Column>
            <Grid.Row>
              <Image size="medium" src={logo} centered className="logo" />
            </Grid.Row>
            <Grid.Row>
              <Segment>
                <Label ribbon>Create a New Chivvy Account</Label>
                <Form>
                  {this.state.emailExists ? <Message color='orange'>This Email is Already Taken as a Username</Message> : null}

                  <Form.Input
                    label='Email (Username)'
                    placeholder='me@myorganization.org'
                    value={email}
                    onChange={this.handleFieldChange.bind(this, 'email')}
                  />

                  {this.state.emailMisformatted && (
                    <Message negative>Please Make Sure your Email is Entered Correctly</Message>
                  )}

                  {errorsMap.email && (
                    <Message negative>{errorsMap.email}</Message>
                  )}

                  <Form.Input
                    label='First/Given Name'
                    placeholder='Jane'
                    value={firstName}
                    onChange={this.handleFieldChange.bind(this, 'firstName')}
                  />
                  <Form.Input
                    label='Last/Family Name'
                    placeholder='Doe'
                    value={lastName}
                    onChange={this.handleFieldChange.bind(this, 'lastName')}
                  />

                  {errorsMap.name && (
                    <Message negative>{errorsMap.name}</Message>
                  )}

                  <Form.Input
                    label='Organization Name'
                    placeholder='My Organization'
                    value={orgName}
                    onChange={this.handleFieldChange.bind(this, 'orgName')}
                  />

                  {this.state.groupExists && (
                    <Message color='orange'>This Organization Name is Already in Use</Message>
                  )}

                  {errorsMap.orgName && (
                    <Message negative>{errorsMap.orgName}</Message>
                  )}

                  <Form.Input
                    label='Password'
                    placeholder='Password'
                    value={password}
                    onChange={this.handleFieldChange.bind(this, 'password')}
                  />

                  {errorsMap.password && (
                    <Message negative>{errorsMap.password}</Message>
                  )}

                  <Form.Input
                    label='Confirm Password'
                    placeholder='Retype your Password to Confirm'
                    value={confirmPassword}
                    onChange={this.handleFieldChange.bind(this, 'confirmPassword')}
                  />

                  {this.state.passwordMismatch && (
                    <Message negative>Please Make Sure Passwords Match ...</Message>
                  )}

                  <Form.Dropdown
                    label='Select Account Type'
                    selection
                    value={isEventProducer}
                    placeholder='Select Account Type'
                    options={accountTypeOpts}
                    onChange={this.handleFieldChange.bind(this, 'isEventProducer')}
                  />
                  <Message>
                    <em>
                      Please Note: <br />
                      <b>Providers</b> are users who add event info to the Chivvy archive -- both for themselves to manage, share, and export to their own website, and for Chivvy Curators to access ...
                      <br />
                      <b>Curators</b> are users who find, tweak, and export event info, both from Chivvy Providers and from event sites such as Eventbrite and Ticketmaster ...
                    </em>
                  </Message>

                  {(this.state.hasError && hasFieldErrors) && (
                    <Message negative>One or more fields have an error</Message>
                  )}

                  {(this.state.hasError && !hasFieldErrors) && (
                    <Message negative>An error occurred while trying to sign you up.</Message>
                  )}

                  {this.state.formIncomplete && (
                    <Message negative>Please Make Sure all Fields are Properly Filled</Message>
                  )}
                </Form>

                <Button onClick={this.clearWarnings}>Create Account</Button>
              </Segment>

              <Modal open={this.state.registerSuccessModalOpen} closeOnDimmerClick closeOnDocumentClick closeIcon onClose={this.closeModal}>
                <Modal.Header><Image size='tiny' src={icon} />Woohoo! You're on Chivvy Now!</Modal.Header>
                <Modal.Content image>
                  <Message color='teal'>
                    <Message.Header>First Things First ...</Message.Header>
                    <List ordered>
                      <List.Item>You'll receive a confirmation email (it may be in your spam folder). Follow the prompts to confirm your Chivvy account ...</List.Item>
                      <List.Item as='a' href='/login'>Log on to Chivvy!</List.Item>
                      <List.Item as='a' href='/account'>Go to your account settings and fill out your Profile. (this is also where you add new users to your account)</List.Item>
                    </List>
                  </Message>
                </Modal.Content>
              </Modal>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
