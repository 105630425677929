import React, { Component, useEffect, useState } from 'react';
import {
	Redirect,
	Route,
	NavLink,
	Switch,
	withRouter,
	Router,
} from 'react-router-dom';
import { Menu, Dropdown, Image, Message } from 'semantic-ui-react';

import _ from 'lodash';

import LoginForm from './components/LoginForm';
import EventManage from './components/EventManage';
import EventbriteActivation from './components/EventbriteActivation';
import ProducerEventLive from './components/ProducerEventLive';
import DistrictManage from './components/DistrictManage';
import PromoterPublish from './components/PromoterPublish';
import PromoterCurate from './components/PromoterCurate';
import PromoterEventLive from './components/PromoterEventLive';
import UserRegistration from './components/UserRegistration';
import Add from './components/Add';
import Account from './components/Account';
import './App.css';

import logo from './assets/ChivvyLogoPrimary-500.svg';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import API from './services/API'
import { useTracking } from './hooks/useTracking';

const SecretRoute = ({ component: Component, token, user }, ...rest) => (
	<Route
		{...rest}
		render={props =>
			<Component token={token} user={user} {...props} />
		}
	/>
);

const ProducerRoute = (
	{ component: Component, token, user },
	...rest
) => {
	return (
		<Route
			{...rest}
			render={props =>
				user['custom:isEventProducer'] &&
				user['custom:isEventProducer'] === '1' ? (
					<Component token={token} user={user} {...props} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

const PromoterRoute = ({ component: Component, user }, ...rest) => (
	<Route
		{...rest}
		render={props =>
			!user['custom:isEventProducer'] ||
			user['custom:isEventProducer'] === '0' ? (
				<Component {...props} />
			) : (
				// <Redirect
				// 	to={{
				// 		pathname: '/',
				// 		state: { from: props.location },
				// 	}}
				// />
				<Component {...props} />
			)
		}
	/>
);

const NavBar = withRouter(props => {
	let menuItems;
	const { user, onLogout } = props;
	if (
		user['custom:isEventProducer'] &&
		user['custom:isEventProducer'] === '1'
	) {
		menuItems = [
			<Menu.Item key="add-page">
				<NavLink to="/add" activeClassName="current-tab">
					Add
				</NavLink>
			</Menu.Item>,
			<Menu.Item key="manage-page">
				<NavLink to="/manage" activeClassName="current-tab">
					Manage
				</NavLink>
			</Menu.Item>,
			<Menu.Item key="live-page">
				<NavLink to="/live" activeClassName="current-tab">
					Live
				</NavLink>
			</Menu.Item>,
		];
	} else {
		menuItems = [
			<Menu.Item key="map-page">
				<NavLink to="/map" activeClassName="current-tab">
					Map
				</NavLink>
			</Menu.Item>,
			<Menu.Item key="curate-page">
				<NavLink to="/curate" activeClassName="current-tab">
					Curate
				</NavLink>
			</Menu.Item>,
			<Menu.Item key="publish-page">
				<NavLink to="/publish" activeClassName="current-tab">
					Publish
				</NavLink>
			</Menu.Item>,
			<Menu.Item key="live-page">
				<NavLink to="/curlive" activeClassName="current-tab">
					Live
				</NavLink>
			</Menu.Item>,
		];
	}

	return !_.isEmpty(user) ? (
		<Menu fixed="top" className="appNavigation">
			<Menu.Item>
				<Image
					size="small"
					as="a"
					src={logo}
					href="https://getchivvy.com"
					target="_blank"
				/>
			</Menu.Item>
			{menuItems}
			<Menu.Menu position="right">
				<Dropdown
					item
					simple
					text={
						user.name
							? user.name + ' ' + user.family_name
							: ''
					}
				>
					<Dropdown.Menu>
						<Dropdown.Item>
							<NavLink to="/account" activeClassName="current-tab">
								My Account
							</NavLink>
						</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item
							onClick={() => {
								onLogout();
								props.history.push('/');
							}}
						>
							Log Out
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Menu>
		</Menu>
	) : null;
});

function App (){
	useTracking()
	const [state, setState] = useState({
		user: {}
	})

	useEffect(() => {
		API.get('/users/me').then((userResponse) => {
			setState({
				user: userResponse.data,
			});
		});
	})
	function handleLogin(user){
		setState({
			user
		});
	};

	function handlePasswordChanged () {
		setState({
			passwordChangeSuccessful: true,
			messageVisible: true,
		});

		setTimeout(() => {
			setState({ messageVisible: false });
		}, 3000);
	};

	function handleLogout (){
		API.get('/auth/logout').then(() => {
			setState({
				user: {},
			});
		})
		window.location.href = '/login'
	};

	function renderRoot () {
		let root = <SecretRoute path="/" exact component={() => <div />} />;

		if (!_.isEmpty(state.user)) {
			root =
				state.user['custom:isEventProducer'] &&
				state.user['custom:isEventProducer'] === '1' ? (
					<SecretRoute path="/" exact component={Add} />
				) : (
					<SecretRoute path="/" exact component={DistrictManage} />
				);
		}

		return root;
	};

		return (
			<div className="App">
				{state.passwordChangeSuccessful && state.messageVisible && (
					<Message
						success
						style={{
							position: 'absolute',
							width: '300px',
							marginTop: '15px',
							left: 'calc(50% - 150px)',
						}}
					>
						Password changed!
					</Message>
				)}

				<NavBar
					className="navBar"
					user={state.user}
					onLogout={handleLogout}
				/>

				<Switch>
					<Route
						path="/login"
						render={props => (
							<LoginForm {...props} onLogin={handleLogin} />
						)}
					/>
					<Route
						path="/forgotpassword"
						render={props => (
							<ForgotPasswordForm
								{...props}
								onPasswordChanged={handlePasswordChanged}
							/>
						)}
					/>
					<Route
						path="/register"
						render={props => <UserRegistration {...props} />}
					/>

					{renderRoot()}

					<ProducerRoute
						path="/add"
						component={Add}
						user={state.user}
						token={state.token}
						user={state.user}
					/>
					<ProducerRoute
						path="/manage"
						component={EventManage}
						user={state.user}
						token={state.token}
						user={state.user}
					/>
					<ProducerRoute
						path="/live"
						component={ProducerEventLive}
						user={state.user}
						token={state.token}
						user={state.user}
					/>

					<PromoterRoute
						path="/curate"
						component={PromoterCurate}
						user={state.user}
						token={state.token}
						user={state.user}
					/>
					<PromoterRoute
						path="/publish"
						component={PromoterPublish}
						user={state.user}
						token={state.token}
						user={state.user}
					/>
					<PromoterRoute
						path="/curlive"
						component={PromoterEventLive}
						user={state.user}
						token={state.token}
						user={state.user}
					/>
					<PromoterRoute
						path="/map"
						component={DistrictManage}
						user={state.user}
						token={state.token}
						user={state.user}
					/>
					<SecretRoute
						path="/account"
						component={Account}
						token={state.token}
						user={state.user}
					/>
					<SecretRoute
						path="/eventbriteactivation"
						component={EventbriteActivation}
						token={state.token}
						user={state.user}
					/>
				</Switch>
			</div>
		);
}

export default App;
