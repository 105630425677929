import React, { Component } from 'react';
import API from '../../services/API';
import axios from 'axios';
import {
	Image,
	Message,
	Segment,
} from 'semantic-ui-react';
import iconLogo from '../../assets/Icon-Blue.jpg';
import logo from '../../assets/ChivvyLogoPrimary-500.svg';
import './eventbriteActivationStyles.css';

export default class EventbriteActivation extends Component {
	state = {
		loading: true,
	};

	async componentDidMount() {
		const clientResponse = await API.get('/clients')

		const currentURL = window.location.href;

		const userEventbriteToken = currentURL.slice(-20);
	
		const eventbriteOrganizationResponse = await axios.get('https://www.eventbriteapi.com/v3/users/me/organizations/', {
			headers: { Authorization: `Bearer ${userEventbriteToken}` },
		})

		const organizationID = eventbriteOrganizationResponse.data.organizations[0].id;

		let client = clientResponse.data.content;

		client.eventbrite_org_id = organizationID;
		client.eventbrite_import_on = 1;

		const updateClientResponse = await API.post('/clients', {
			client: client,
		});

		if (updateClientResponse.data.success) {
			this.setState({
				loading: false
			});
		}
	}

	render() {
		return (
			this.state.loading ?
				<Image src={iconLogo} size='tiny' className='App-logo'/>
			:
				<Segment className='eventbriteActivationCompleteMessage'>
					<Image src={logo} size='medium' />
					<Message positive>
						<Message.Header>Sweet ...</Message.Header>
						<p>Eventbrite imports are now active for your account. Each time you visit the <a href='/manage'>Manage page</a>, Chivvy will scan the database for your events and transfer ownership of them to you.</p>
						<br></br>
						<p>Go back to the <a href='/account'>My Account page</a></p>
						<br></br>
						<p>Go to the <a href='/manage'>Manage page</a></p>
					</Message>
				</Segment>
		)
	}
}
