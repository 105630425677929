import React, { Component } from 'react';
import {
  Card,
  Label,
  Modal,
  Form,
  Button,
  Dropdown,
  Icon,
  Image,
  Divider
} from 'semantic-ui-react';
import _ from 'lodash';
import states from '../../assets/states.js';

export default class ProfileCard extends Component {
  state = {
    client: this.props.client,
    address: this.props.client.address,
    city: this.props.client.city,
    contact_email: this.props.client.contact_email,
    description: this.props.client.description,
    display_name: this.props.client.display_name,
    state: this.props.client.state,
    tags: this.props.client.tags,
    url: this.props.client.url,
    url_logo: this.props.client.url_logo,
    zip: this.props.client.zip,
    tagOpts: this.props.tagOpts,
    modalOpen: false,
  };

  editProfile = () => {
    this.closeModal();
    this.props.editProfile(this.state.client);
  }

  handleFieldChange = (name, e, { value }) => {
		this.setState({
      [name]: value,
      client: Object.assign(this.state.client, { [name]: value })
		});
	};

  handleAddition = (e, { value }) => {
    this.setState(prevState => ({
      tagOpts: [{ text: value, value }, ...prevState.tagOpts],
    }))
  }

  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  }

  openModal = () => {
    this.setState({
      modalOpen: true
    })
  }

  render() {

    let {
      address,
      city,
      contact_email,
      description,
      display_name,
      state,
      tags,
      url,
      url_logo,
      zip,
      tagOpts,
    } = this.state;

    let tagsLabels = [];

    if (tags.length) {
      tags.map(tag => {
        tagsLabels.push(<Label key={tag} color='pink'>{tag}</Label>);
        tagOpts.push({key:tag, text: tag, value: tag});
      })
    } else {
      tagsLabels.push(<Label color='orange'>Add tags describing your organization so other users can connect with you! </Label>);
    }

    let tagList = (
      <div className='tagsList'>
        <Label>Tags: </Label>
        {tagsLabels}
      </div>
    );

    let location = (address === '' && city === '' && state === '' && zip === '' ?
      <div>
        <Label>Location:</Label>
        <Label color='orange'>Please add location information</Label>
      </div>
    :
      <div>
        <Label>Location: </Label>
        {address !== '' ? <p>{address},</p> : null}
        {city !== '' ? <p>{city},</p> : null}
        {state !== '' ? <p>{state},</p> : null}
        {zip !== '' ? <p>{zip}</p> : null}
      </div>
    );

    let email = (
      <div>
        <Label>Contact Email: </Label>
          {contact_email && contact_email.trim() === '' ?
            <Label color='orange'>Please add a contact email</Label>
          :
            <a href={`mailto:${contact_email}`}>{contact_email}</a>
          }
      </div>
    );

    let site = (
      <div>
        <Label>Website: </Label>
        {url === '' ?
          <Label color='orange'>Please add an Organization Website Link</Label>
        :
        <a href={url}>{display_name || this.state.client.name} website</a>
        }
      </div>
    );

    let editProfileModal = (
      <div>
        <Modal open={this.state.modalOpen} onClose={this.closeModal} className='editProfileModal' trigger={<Button fluid icon color='teal' onClick={this.openModal}><Icon name='edit'/>Edit Profile</Button>}>
          <Modal.Header>
            <Icon name='edit' />
            Edit Profile
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Input
                value={display_name}
                placeholder='Institution Name to be Displayed on Chivvy'
                label='Display Name'
                onChange={this.handleFieldChange.bind(this,'display_name')}
              />
              <Form.Input
                value={url}
                placeholder='www.myinstitution.com'
                label='Website'
                onChange={this.handleFieldChange.bind(this,'url')}
              />
              <Form.Input
                value={url_logo}
                placeholder='www.myinstitution.com/myinstitutionlogo.png'
                label='Logo'
                onChange={this.handleFieldChange.bind(this,'url_logo')}
              />
              <Form.Input
                value={contact_email}
                placeholder='me@example.com'
                label='Contact Email'
                onChange={this.handleFieldChange.bind(this,'contact_email')}
              />
              <Form.TextArea
                value={description}
                placeholder='Description ...'
                label='Description'
                onChange={this.handleFieldChange.bind(this,'description')}
              />
              <Form.Input
                value={address}
                placeholder='1234 Chivvy Ln.'
                label='Address'
                onChange={this.handleFieldChange.bind(this,'address')}
              />
              <Form.Input
                value={city}
                placeholder='City'
                label='City'
                onChange={this.handleFieldChange.bind(this,'city')}
              />
              <Form.Dropdown
                label="State"
                options={states}
                placeholder="Oklahoma"
                search
                selection
                value={state}
                onChange={this.handleFieldChange.bind(this, 'state')}
              />
              <Form.Input
                value={zip}
                placeholder='12345'
                label='Zip'
                onChange={this.handleFieldChange.bind(this,'zip')}
              />
              <div className="custom-field">
    						<Label>Tags: </Label>
    						<Dropdown
    							fluid
    							multiple
    							search
    							selection
    							value={tags}
    							allowAdditions
    							additionLabel="Custom Tag: "
    							onAddItem={this.handleAddition}
    							placeholder="Select tags for organization"
    							options={tagOpts}
    							onChange={this.handleFieldChange.bind(this, 'tags')}
    						/>
    					</div>
            </Form>
            <Divider />
            <div className="center">
              {this.props.editable ?
                <Button onClick={this.editProfile} color="pink" inverted fluid>
                  Edit Profile
                </Button>
              :
                null
              }
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );

    return (
      <Card fluid>
        <Image size={url_logo && url_logo.trim() == '' ? 'tiny' : null} src={url_logo && url_logo.trim() != '' ? url_logo : 'https://react.semantic-ui.com/images/wireframe/image.png'} wrapped ui={false} />
        <Card.Content>
          <Card.Header>
            {display_name && display_name.trim() != '' ? display_name : '[Please add organization display name]'}
          </Card.Header>
          <Card.Description>
            {description}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {site}
          {email}
          {location}
          {tagList}
          {this.props.editable ? editProfileModal : null}
        </Card.Content>
      </Card>
    );
  }
}
