import lodash from 'lodash';
import React, { Component } from 'react';
import {
	Button,
	Form,
	Image,
	Message,
	Segment,
	Modal,
	Grid,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import API from '../../services/API';

import logo from '../../assets/ChivvyLogoPrimary-500.svg';
import './loginFormStyles.css';

class LoginForm extends Component {
	state = {
		username: '',
		password: '',
		redirectToPreviousRoute: false,
		redirectToRegister: false,
		authenticationError: false,
		newPassword: '',
		newPasswordConfirm: '',
	};

	componentWillMount() {
		// Getting the current user will fail if not logged in
		API.get('/users/me').then(() => {
			this.setState({ redirectToPreviousRoute: true });
		}).catch((err) => {
			this.setState({ redirectToPreviousRoute: false });
		});
	}

	handleFieldChange = (property, event) => {
		const value = event.target.value;

		this.setState({
			authenticationError: false,
			[property]: value,
		});
	};

	login = () => {
		const { username, password } = this.state;

		this.setState({
			authenticationError: false,
		});

		API.post('/auth/login', {
			username: username,
			password: password,
		}).then((authResponse) => {
			this.props.onLogin(authResponse.data);
			this.setState({
				redirectToPreviousRoute: true
			})
		}).catch((err) => {
			const status = String(lodash.get(err, 'response.status', 0));

			if (status === '400' || status === '401') {
				this.setState({
					authenticationError: true,
				});
			}

			console.error(err)
		});
	};

	register = () => {
		this.setState({
			redirectToRegister: true
		})
	};

	render() {
		const { from } = this.props.location.state || { from: { pathname: '/' } };
		const { redirectToPreviousRoute, redirectToRegister } = this.state;

		if (redirectToPreviousRoute) {
			return <Redirect to={from} />;
		}

		if (redirectToRegister) {
			return <Redirect to="/register" />;
		}

		return (
			<div className="login-form">
				<Grid>
					<Grid.Column>
						<Grid.Row centered columns={1}>
							<Image size="medium" src={logo} centered className="logo" />
						</Grid.Row>
						<Grid.Row>
							<Form size="large">
								<Segment>
									<Form.Input
										fluid
										value={this.state.username}
										icon="user"
										iconPosition="left"
										placeholder="E-mail address"
										onChange={this.handleFieldChange.bind(this, 'username')}
									/>
									<Form.Input
										fluid
										value={this.state.password}
										icon="lock"
										iconPosition="left"
										placeholder="Password"
										type="password"
										onChange={this.handleFieldChange.bind(this, 'password')}
									/>
									{this.state.authenticationError && (
										<Message negative>Invalid username or password.</Message>
									)}
									<Message>
										{/* This should probably be on registration? */}
										By logging in, you agree to our subscription and access
										agreement.
									</Message>
									<Button
										fluid
										size="large"
										onClick={this.login}
										className="loginButton"
									>
										Login
									</Button>
									<br />
									<Button
										fluid
										size="large"
										onClick={this.register}
										className="registerButton"
									>
										Register
									</Button>
								</Segment>
							</Form>
						</Grid.Row>
						<Grid.Row>
							<div className="links">
								<a href="/forgotpassword">Forgot Password?</a>
							</div>
						</Grid.Row>
					</Grid.Column>
				</Grid>
			</div>
		);
	}
}

export default LoginForm;
