import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

function goToLogin() {
  const path = window.location.pathname;

  if (path === '/login' || path === '/forgotpassword' || path === '/register') {
    return;
  }

  window.location = '/login';
}

const instance = axios.create({
  baseURL: BASE_URL + '/v1',
  withCredentials: true,
});

instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const status = error.response && error.response.status;
  console.log('status', status)
  if (status === 401 || status === 403) {
    goToLogin();
  }

  throw error;
});

export default instance;

export const request = function apiRequest(options) {
  const requestOptions = {
    withCredentials: true,
    ...options,
    baseURL: BASE_URL,
  };

  if (options.cancelable) {
    const cancelTokenSource = axios.CancelToken.source();
    options.cancelToken = cancelTokenSource.token;
    options.cancelable(cancelTokenSource.cancel);

    delete requestOptions.cancelable;
  }

  return axios(
    requestOptions
  ).catch((requestError) => {
    if (axios.isCancel(requestError)) {
      const canceledError = new Error('canceled');
      canceledError.canceled = true;
      throw canceledError;
    }

    const response = requestError.response || {};

    if (response.status === 401 || response.status === 403) {
      goToLogin();
    }

    let safeError = {
      type: 'UnknownServerError',
      message: 'An unknown internal server error occurred.',
    };
    if (response.data) {
      if (typeof response.data === 'string') {
        safeError = {
          type: 'ServerError',
          message: response.data
        };
      } else {
        safeError = response.data;
      }
    }

    throw safeError;
  }).then((response) => {
    if (options.rawResponse) {
      return response;
    } else if (!response || !response.data) {
      return null;
    }

    return response.data;
  });
};
