/* eslint-disable no-process-env */

const environment = String(process.env.NODE_ENV || 'development');

export default {
	env: environment,
	envIsDev: (environment === 'development'),
	envIsProd: (environment === 'production'),

	facebook: {
		appId: process.env.REACT_APP_FACEBOOK_APP_ID,
	}
};
