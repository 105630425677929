import React, { Component } from 'react';
import {
	Segment,
	Button,
	Icon,
	Grid,
	Modal,
	Form,
	Accordion,
	Dropdown,
	Label,
} from 'semantic-ui-react';
import API from '../../services/API';
import toastr from 'toastr';
import ProfileCard from '../ProfileCard';
import './accountStyles.css';

export default class Account extends Component {
	state = {
		client: null,
		users: [],
		activeIndex: null,
		tagsMaster: [],
		tagOpts: [],
		addUserPassword: '',
		addUserConfirmPassword: '',
		addUserEmail: '',
		addUserName: '',
		addUserSurname: '',
	};

	async componentDidMount() {

		const allUsersResponse = await API.get('/users');
		const clientsResponse = await API.get('/clients');

		const tagsResponse = await API.get('/tags')

		let clientTags = [];
		if (
			clientsResponse.data.content.tags !== null &&
			clientsResponse.data.content.tags !== ''
		) {
			clientTags = clientsResponse.data.content.tags.split(',');
		}
		clientsResponse.data.content.tags = clientTags;

		Object.keys(clientsResponse.data.content).forEach(function(key) {
	    if(clientsResponse.data.content[key] === null) {
	        clientsResponse.data.content[key] = '';
	    }
		})

		let tagOpts = [];

		tagsResponse.data.content.map(tag => {
			tagOpts.push({ key: tag.tag, text: tag.tag, value: tag.tag });
			return tag;
		});

		this.setState({
			client: clientsResponse.data.content,
			tagsMaster: tagsResponse.data.content,
			tagOpts: tagOpts,
			users: allUsersResponse.data.content,
		});
	}

	handleFieldChange = (name, e, { value }) => {
		this.setState({
			[name]: value,
		});
	};

	handleVenueSearch = () => {
		console.log(this.state.venue_ids);
	}

	handleNewUser = () => {
		let {
			addUserName,
			addUserEmail,
			addUserSurname,
			addUserPassword,
			addUserConfirmPassword,
			users
		} = this.state;

		const toastrConfig = {
			preventDuplicates: false,
			timeOut: '5000',
			hideMethod: 'fadeOut',
			progressBar: false,
			extendedTimeOut: '1000',
			showEasing: 'swing',
			closeButton: true,
			hideEasing: 'linear',
			positionClass: 'toast-top-right',
			newestOnTop: false,
			showDuration: '300',
			debug: false,
			hideDuration: '1000',
			showMethod: 'fadeIn',
		};

		toastr.options = toastrConfig;

		let isProducer = '0';

		if (
			this.props.user['custom:isEventProducer'] &&
			this.props.user['custom:isEventProducer'] === '1'
		) {
			isProducer = '1';
		}

		if (users.findIndex(p => p.email.toLowerCase() === addUserEmail.toLowerCase()) > -1) {
			toastr.warning("A User with that Email Already Exists ...", 'Sorry ...');
		} else if (addUserPassword !== addUserConfirmPassword) {
			toastr.warning("Passwords Don't Match ...", 'Whoops ...');
		} else if (
			addUserPassword.trim() === '' ||
			addUserConfirmPassword.trim() === '' ||
			addUserName.trim() === '' ||
			addUserSurname.trim() === '' ||
			addUserEmail.trim() === ''
		) {
			toastr.warning('Make sure all fields are filled out ...', 'Whoops ...');
		} else if (
			addUserEmail.indexOf('@') === -1 ||
			addUserEmail.indexOf('.') === -1
		) {
			toastr.warning('Make sure email address is valid ...', 'Whoops ...');
		} else {
			this.createUserAddToGroup(
				addUserEmail,
				addUserPassword,
				isProducer,
				addUserName,
				addUserSurname,
				this.state.client.cognito_group
			);
		}
	};

	createUserAddToGroup = async (
		addUserEmail,
		addUserPassword,
		isProducer,
		addUserName,
		addUserSurname,
		cognito_group
	) => {
		const addUserToGroupResponse = await API.post('/auth/register/user', {
			email: addUserEmail,
			password: addUserPassword,
			isEventProducer: isProducer,
			name: addUserName,
			familyName: addUserSurname,
			groupName: cognito_group
		});

		console.log(addUserToGroupResponse);

		if (addUserToGroupResponse.data.success) {
			this.prepNewUserForDBInsert();
		}
	};

	prepNewUserForDBInsert = () => {
		let newUser = {};

		newUser.name = `${this.state.addUserName} ${this.state.addUserSurname}`;
		newUser.email = `${this.state.addUserEmail}`;

		this.putUser(newUser);
	};

	putUser = async user => {
		const toastrConfig = {
			preventDuplicates: false,
			timeOut: '5000',
			hideMethod: 'fadeOut',
			progressBar: false,
			extendedTimeOut: '1000',
			showEasing: 'swing',
			closeButton: true,
			hideEasing: 'linear',
			positionClass: 'toast-top-right',
			newestOnTop: false,
			showDuration: '300',
			debug: false,
			hideDuration: '1000',
			showMethod: 'fadeIn',
		};

		toastr.options = toastrConfig;

		const { name, email } = user;
		const userResponse = await API.post('/users', {
			user: user,
			newUser: false,
		});

		if (userResponse.data.success) {
			this.setState({
				addUserPassword: '',
				addUserConfirmPassword: '',
				addUserEmail: '',
				addUserName: '',
				addUserSurname: '',
			});

			toastr.success('User successfully added ...', 'Woohoo!');
		}
	};

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	editProfile = client => {
		delete client.role;
		delete client.status;
		delete client.id;

		this.putClient(client);
	};

	eventbriteAuth = () => {
		window.location.href = `https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=3TAIPNMJI5ZLP2QNUN&redirect_uri=${window.location.origin}/eventbriteactivation`;
	}

	putClient = async client => {
		const toastrConfig = {
			preventDuplicates: false,
			timeOut: '5000',
			hideMethod: 'fadeOut',
			progressBar: false,
			extendedTimeOut: '1000',
			showEasing: 'swing',
			closeButton: true,
			hideEasing: 'linear',
			positionClass: 'toast-top-right',
			newestOnTop: false,
			showDuration: '300',
			debug: false,
			hideDuration: '1000',
			showMethod: 'fadeIn',
		};

		toastr.options = toastrConfig;

		try {
			const clientsResponse = await API.post('/clients', {
				client: client,
			});

			if (clientsResponse.data.success) {
				toastr.success('Profile updated sucessfully ...', 'Woohoo!');
			}
		} catch (error) {
			console.log(error);

			toastr.error(`${error}`, 'Whoops ...');
		}
	};

	render() {
		const {
			addUserPassword,
			addUserName,
			addUserEmail,
			addUserSurname,
			addUserConfirmPassword,
			activeIndex,
			user,
		} = this.state;

		return (
			<Segment className="myAccountContainer" raised>
				<Grid columns={2}>
					<Grid.Column width={8}>
						<Segment raised>
							{this.state.client ? (
								<ProfileCard
									client={this.state.client}
									editable={true}
									editProfile={this.editProfile}
									tagOpts={this.state.tagOpts}
								/>
							) : null}
						</Segment>
					</Grid.Column>
					<Grid.Column width={8}>
						<Accordion fluid styled>
							<Accordion.Title
								active={activeIndex === 0}
								index={0}
								onClick={this.handleClick}
							>
								<Icon name="dropdown" />
								Add New User to Client Group
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 0}>
								<Modal
									trigger={
										<Button color="teal" fluid icon>
											<Icon name="add user" /> Add User to Client Group
										</Button>
									}
								>
									<Modal.Header>
										<Icon name="add user" />
										Add New User to Client Group
									</Modal.Header>
									<Modal.Content>
										<Form>
											<Form.Input
												value={addUserName}
												placeholder="First/Given Name"
												label="First/Given Name"
												onChange={this.handleFieldChange.bind(
													this,
													'addUserName'
												)}
											/>
											<Form.Input
												value={addUserSurname}
												placeholder="Last/Family Name"
												label="Last/Family Name"
												onChange={this.handleFieldChange.bind(
													this,
													'addUserSurname'
												)}
											/>
											<Form.Input
												value={addUserEmail}
												placeholder="me@myinstitution.org"
												label="Email"
												onChange={this.handleFieldChange.bind(
													this,
													'addUserEmail'
												)}
											/>
											<Form.Input
												value={addUserPassword}
												placeholder="Password"
												label="Password"
												onChange={this.handleFieldChange.bind(
													this,
													'addUserPassword'
												)}
											/>
											<Form.Input
												value={addUserConfirmPassword}
												placeholder="Confirm Password"
												label="Confirm Password"
												onChange={this.handleFieldChange.bind(
													this,
													'addUserConfirmPassword'
												)}
											/>
										</Form>
										<div className="center">
											<Button
												onClick={this.handleNewUser}
												color="pink"
												inverted
											>
												Add User
											</Button>
										</div>
									</Modal.Content>
								</Modal>
							</Accordion.Content>
							{this.props.user['custom:isEventProducer'] && this.props.user['custom:isEventProducer'] === '1' ?
								<div>
									<Accordion.Title
										active={activeIndex === 1}
										index={1}
										onClick={this.handleClick}
									>
										<Icon name="dropdown" />
										Activate Eventbrite Import
									</Accordion.Title>
									<Accordion.Content active={activeIndex === 1}>
										<Button className='eventbriteImportButton' fluid icon onClick={this.eventbriteAuth}>
											<Icon name='arrow alternate circle down outline' />
											Activate Eventbrite Import
										</Button>
									</Accordion.Content>
								</div>
								:
								null
							}
						</Accordion>
					</Grid.Column>
				</Grid>
			</Segment>
		);
	}
}
