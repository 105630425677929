import * as Facebook from 'fb-sdk-wrapper';
import lodash from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Divider, Header, Icon, Image, List, Segment } from 'semantic-ui-react';
import { Subscribe } from 'unstated';

import iconLogo from '../../assets/Icon-Blue.jpg';
import config from '../../config/index';
import apiEventCreateStore from '../../stores/api/event/apiEventCreateStore';
import apiEventFacebookGetAllStore from '../../stores/api/event/apiEventFacebookGetAllStore';

import './addFacebookEvent.css';

/**
 * Whether or not to activate the facebook event imports.
 * When this is ready for production, remove the config.isProd check.
 *
 * @type {boolean}
 */
const USE_FACEBOOK = Boolean(config.facebook.appId && !config.envIsProd);

/**
 * AddFacebookEvent component
 */
class AddFacebookEvent extends Component {
	/**
	 * The component state.
	 *
	 * @type {{}}
	 */
	state = {
		facebookInitializing: false,
		facebookLoading: false,
		facebookLoggedIn: false,
		facebookEvents: null,
		importedEventIds: [],
	};

	/**
	 * Triggered when the component is mounted to the page.
	 */
	componentDidMount() {
		this.initFacebook();
	}

	/**
	 * Inits the facebook API.
	 */
	initFacebook = () => {
		if (!USE_FACEBOOK) {
			return;
		}

		this.setState({
			facebookInitializing: true,
		});

		Facebook.load().then(() => {
			Facebook.init({
				appId: config.facebook.appId,
			});

			this.setState({
				facebookInitializing: false,
			});
		});
	};

	/**
	 * Connects the user to facebook.
	 *
	 * @returns {Promise}
	 */
	connectToFacebook = async () => {
		this.setState({
			facebookLoggedIn: false,
			facebookLoading: true,
		});

		let fbResponse;
		try {
			fbResponse = await Facebook.login({
				scope: 'user_events',
				return_scopes: true,
				auth_type: 'reauthenticate',
			});
		} catch (facebookLoginError) {
			console.error(facebookLoginError);

			this.setState({
				facebookLoggedIn: false,
			});

			return;
		}

		const isLoggedIn = Boolean(fbResponse && fbResponse.status === 'connected');

		this.setState({
			facebookLoggedIn: isLoggedIn,
		});

		if (isLoggedIn) {
			this.getFacebookEvents();
		}
	};

	/**
	 * Gets the user's facebook events.
	 *
	 * @returns {Promise}
	 */
	getFacebookEvents = async () => {
		this.setState({
			facebookLoading: true,
		});

		// Fetch the user's events from facebook.
		const facebookGetPromise = Facebook.api('/me/events');

		// Also fetch the facebook events that have already been imported.
		const getImportedStore = apiEventFacebookGetAllStore.single();
		getImportedStore.request();
		const importedGetPromise = getImportedStore.getPromise();

		let eventsResponse;
		try {
			eventsResponse = await facebookGetPromise;
		} catch (facebookEventsError) {
			console.error(facebookEventsError);

			this.setState({
				facebookLoading: false,
				facebookEvents: null,
			});

			return;
		}

		let importedFacebookEventIds = [];
		try {
			const foundEventIds = await importedGetPromise;

			importedFacebookEventIds = (foundEventIds || []).map(String);
		} catch (localEventsError) {
			// Do nothing, we will just consider it empty.
		}

		const events = (eventsResponse && eventsResponse.data) ? eventsResponse.data : null;

		this.setState({
			facebookLoading: false,
			facebookEvents: events,
			importedEventIds: importedFacebookEventIds,
		});
	};

	/**
	 * Saves the active events to the server.
	 *
	 * @param {{}} facebookEvent
	 * @returns {function}
	 */
	saveEvent = (facebookEvent) => {
		if (!facebookEvent) {
			return () => {};
		}

		return () => {
			const place = facebookEvent.place || {};
			const { location } = place;

			const eventId = String(facebookEvent.id);

			const venue = {
				name: place.name,
			};

			if (location && location.street) {
				venue.address = location.street;
				venue.city = location.city;
				venue.state = location.state;
				venue.zip = location.zip;
			} else if (location && location.latitude) {
				venue.lat = location.latitude;
				venue.lon = location.longitude;
			} else {
				// If no location data is present then the name could be the address string.
				venue.location = place.name;
			}

			// Pick by will remove any empty values.
			const newEvent = lodash.pickBy({
				title: facebookEvent.name,
				description: facebookEvent.description,
				startDate: facebookEvent.start_time,
				endDate: facebookEvent.end_time,
				source: 'producer-facebook-import',
				status: 'producer-uploaded', // This should be set by the backend.
				venue,
				sourceEventId: eventId,
			});

			const createStore = apiEventCreateStore.get(eventId);
			createStore.request(newEvent);

			createStore.getPromise().then((newEventIds) => {
				const safeEventIds = (newEventIds || []).map(String);

				// Update the importedEventIds array with the new event ids.
				this.setState((prevState) => ({
					importedEventIds: [
						...prevState.importedEventIds,
						...safeEventIds,
					],
				}));
			});
		};
	};

	/**
	 * Determines whether or not the facebook event can be imported.
	 *
	 * @param {{}} facebookEvent
	 * @returns {Error|boolean}
	 */
	isEventInvalid = (facebookEvent) => {
		const checkFor = ['name', 'start_time'];
		const coordinatesCheckFor = ['latitude', 'longitude'];
		const locationCheckFor = ['street', 'city', 'state', 'zip'];

		const missing = [];
		checkFor.forEach((checkName) => {
			if (!facebookEvent[checkName]) {
				missing.push(
					lodash.capitalize(checkName.replace('_', ' '))
				);
			}
		});

		if (!facebookEvent.place) {
			missing.push('Venue');
		}
		if (facebookEvent.place && !facebookEvent.place.name) {
			missing.push('Venue Name');
		}

		if (facebookEvent.place && facebookEvent.place.location) {
			const { location } = facebookEvent.place;

			if (location.latitude || location.longitude) {
				coordinatesCheckFor.forEach((checkName) => {
					if (!location[checkName]) {
						missing.push(
							'Venue ' + lodash.capitalize(checkName)
						);
					}
				});
			} else {
				locationCheckFor.forEach((checkName) => {
					if (!location[checkName]) {
						missing.push(
							'Venue ' + lodash.capitalize(checkName)
						);
					}
				});
			}
		}

		if (!missing.length) {
			return false;
		}

		return new Error(`missing: ${missing.join(', ')}`);
	};


	/**
	 * Renders a single facebook event.
	 *
	 * @param {{}} event
	 * @returns {{}}
	 */
	renderFacebookEvent(event) {
		const eventId = String(event.id);
		const importStore = apiEventCreateStore.get(eventId);

		const isInvalid = this.isEventInvalid(event);

		const wasImported = lodash.includes(this.state.importedEventIds, eventId);

		return (
			<Subscribe to={[importStore]}>
				{(importer) => (
					<div className="fbEvent" key={event.id}>
						<Header as="h3" className="fbEventHeader">
							<span className="fbEventName">{event.name}</span>
						</Header>

						<List>
							{(event.start_time) && (
								<List.Item>
									<Icon name="time" className="fbListIcon" />
									Start: {moment(event.start_time).format('MMMM Do YYYY, h:mm a')}
								</List.Item>
							)}

							{(event.end_time) && (
								<List.Item>
									<Icon name="time" className="fbListIcon" />
									End: {moment(event.end_time).format('MMMM Do YYYY, h:mm a')}
								</List.Item>
							)}

							{(event.place && event.place.name) && (
								<List.Item>
									<Icon name="marker" className="fbListIcon" />
									{event.place.name}
								</List.Item>
							)}
						</List>

						<div className="fbEventDescription">
							<p>{event.description || 'No event description.'}</p>
						</div>

						{(wasImported) && (
							<Segment color="green">
								This event has already been imported.
							</Segment>
						)}

						{(isInvalid) && (
							<Segment color="red">
								This event can not be imported because it is {isInvalid.message}.
							</Segment>
						)}

						{(!isInvalid && !wasImported) && importer.case({
							pre: () => (
								<Button color='pink' onClick={this.saveEvent(event)}>Import Event</Button>
							),
							pending: () => (
								<Segment color="violet">Importing...</Segment>
							),
							fulfilled: () => (
								<Segment color="blue">Event saved.</Segment>
							),
							rejected: (error) => (
								<Segment color="red">
									Error: This event could not be imported {
										(error && error.message) && `[${error.message}]`
									}
								</Segment>
							),
						})}
					</div>
				)}
			</Subscribe>
		);
	}

	/**
	 * Renders the component.
	 *
	 * @returns {*}
	 */
	render() {
		if (!USE_FACEBOOK) {
			return (
				<Segment color="yellow">
					This feature is not currently enabled.
				</Segment>
			);
		}

		if (this.state.facebookInitializing) {
			return (
				<Image src={iconLogo} size='tiny' className='App-logo' />
			);
		}

		if (!this.state.facebookLoggedIn) {
			return (
				<div className="center-content">
					<Button primary size="large" onClick={this.connectToFacebook}>
						<Icon name="facebook" />
						Connect to Facebook
					</Button>
				</div>
			);
		}

		if (this.state.facebookLoading) {
			return (
				<div>
					<div>Fetching Facebook Events...</div>
				</div>
			);
		}

		const events = this.state.facebookEvents || [];

		return (
			<div className="addFacebookEvent">
				<div>
					<Button primary onClick={this.getFacebookEvents}>
						<Icon name="refresh" />
						Refresh List
					</Button>
				</div>

				{(!events.length) && (
					<div>No events to display.</div>
				)}

				{(events.length) && (
					<div>
						<h3>Events from Facebook</h3>

						<Segment>
							{(events.map((event, eventIndex) => {
								return (
									<div key={event.id}>
										{(Boolean(eventIndex)) && (
											<Divider />
										)}

										{this.renderFacebookEvent(event)}
									</div>
								);
							}))}
						</Segment>
					</div>
				)}
			</div>
		);
	};
}

export default AddFacebookEvent;
