import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const useTracking = () => {
  const { listen } = useHistory()

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return
      window.gtag('config', 'UA-162834164-1', { page_path: location.pathname })
    })

    return unlisten
  }, [listen])
}