import axios from 'axios';
import _ from 'underscore';

// Get venues, events, tags, and categories from client's WP site
async function getClientData(type, baseURL) {
    let page = 1;
    let newPage = true;
    let results = [];

    while (newPage == true) {
      const response = await axios.request({
        "baseURL": baseURL,
        "method": "get",
        "return_format": "json",
        "authorization": "",
        "url": `/${type}?page=${page}&per_page=50&${Math.random()*Math.random()}`
      });

      if (type === 'venues') {
        results = results.concat(response.data.venues);
      } else if (type === 'events') {
        results = results.concat(response.data.events);
      } else if (type === 'tags') {
        results = results.concat(response.data.tags);
      } else {
        results = results.concat(response.data.categories);
      }

      page++;

      newPage = response.data.next_rest_url ? true : false;
    }

    return results;
}

async function putVenues(venue, baseURL) {
  let venuesResponse = await axios.request({
    "baseURL": baseURL,
    "method": "post",
    "auth": {
      "username": 'support@getchivvy.com',
      "password": 'ww@%V2Yz@Me*!87ILhDz6IxX'
    },
    "url": `/venues?venue=${venue.venue}&city=${venue.city}&state=${venue.state}&address=${venue.address}&zip=${venue.zip}&id=10`
  });

  return venuesResponse;
}

async function putCats(cat, baseURL) {
  let catResponse = await axios.request({
    "baseURL": baseURL,
    "method": "post",
    "auth": {
      "username": 'support@getchivvy.com',
      "password": 'ww@%V2Yz@Me*!87ILhDz6IxX'
    },
    "url": `/categories?id=10&name=${cat}`
  });

  return catResponse;
}

async function prepVenues(putEvents, baseURL, districtsAsTags, venues, events, tags) {

  let newlyPublishedEvents = [];
  let newlyPublishedEventIds = [];
  let publishedEvents = [];
  let unpublishedEvents = [];

  // separate published and unpublished events
  putEvents.map(event => {
    if (event.status === 'approved') {
      unpublishedEvents.push(event);
    } else {
      publishedEvents.push(event);
    }
  })
  //get newly published events
  let tagMap = {};
  let tagSlugs = [];

  tags.map(function(tag){
    tagMap[`${tag.name}`] = `'${tag.id}'`;
  })

  events.map(function(event){
    if (event.tags && event.tags != null) {
      event.tags.map(function(tag){
        tagSlugs.push(tag.slug);
      })
    }
  })

  publishedEvents.map(function(pubEvent){
    if (tagSlugs.indexOf(`c${pubEvent.id}`) === -1 || !tagMap[`c${pubEvent.id}`]) {
      newlyPublishedEvents.push(pubEvent);
      newlyPublishedEventIds.push(pubEvent.id);
    }
  })

  //find new venues to post
  let venueNames = [];
  let newVenues = [];

  venues.map(venue => {
    if (venue.venue) {
      venue.venue = venue.venue.replace(/&#(\d+);/g, function(match, dec) {
				return String.fromCharCode(dec)
      });

      venue.venue = _.unescape(venue.venue);

      venueNames.push(venue.venue.toLowerCase().trim());
    }
  })

  let uniqueVenueNames = [];

  publishedEvents.map(event => {
    if (venueNames.indexOf(event.name.toLowerCase().trim()) === -1) {
      if (uniqueVenueNames.indexOf(event.name) === -1) {
        newVenues.push({'venue':`${event.name}`,'address':`${event.address}`,'city':`${event.city}`,'state':`${event.state}`,'zip':`${event.zip}`});

        uniqueVenueNames.push(event.name);
      }
    }
  })

  // create new venues if necessary

  if (newVenues.length) {
    let newlyAddedVenues = newVenues.map(async venue => {
      let venuesResponses = [];
      venue.venue = encodeURIComponent(venue.venue);
      let venuesResponse = await putVenues(venue, baseURL);

      venuesResponses.push(venuesResponse.data);

      return venuesResponses;
    });

    const preppedVenues = await Promise.all(newlyAddedVenues);

    preppedVenues.map(ven => {
      venues.push(ven[0]);
    })

    return venues;
  } else {
    return venues;
  }
}

async function prepCats(putEvents, baseURL, districtsAsTags, categories, events, tags) {

  let newlyPublishedEvents = [];
  let newlyPublishedEventIds = [];
  let publishedEvents = [];
  let unpublishedEvents = [];

  // separate published and unpublished events
  putEvents.map(event => {
    if (event.status === 'approved') {
      unpublishedEvents.push(event);
    } else {
      publishedEvents.push(event);
    }
  })

  //get newly published events
  let tagMap = {};
  let tagSlugs = [];

  tags.map(function(tag){
    tagMap[`${tag.name}`] = `'${tag.id}'`;
  })

  events.map(function(event){
    if (event.tags && event.tags != null) {
      event.tags.map(function(tag){
        tagSlugs.push(tag.slug);
      })
    }
  })

  publishedEvents.map(function(pubEvent){
    if (tagSlugs.indexOf(`c${pubEvent.id}`) === -1 || !tagMap[`c${pubEvent.id}`]) {
      newlyPublishedEvents.push(pubEvent);
      newlyPublishedEventIds.push(pubEvent.id);
    }
  })

  //find new categories to post
  let catNames = [];
  let newCats = [];

  categories.map(cat => {
    if (cat.name) {
      cat.name = cat.name.replace(/&#(\d+);/g, function(match, dec) {
				return String.fromCharCode(dec)
      });

      cat.name = _.unescape(cat.name);

      catNames.push(cat.name.toLowerCase().trim());
    }
  })

  newlyPublishedEvents.map(event => {
    if (districtsAsTags && event.districts && event.districts.length) {
      event.districts.map(district => {
        newCats.push(district);
      })
    }

    if (event.tags && event.tags.length) {
      event.tags.map(tag => {
        newCats.push(tag);
      })
    }
  })


  if (newCats.length) {
    newCats = newCats.filter(cat => {
      return catNames.indexOf(cat.toLowerCase().trim()) === -1;
    })

    newCats = [...new Set(newCats)];
  }

  // create new categories if necessary

  if (newCats.length) {
    let newlyAddedCategories = newCats.map(async cat => {
      let catsResponses = [];

      cat = encodeURIComponent(cat);
      let catsResponse = await putCats(cat, baseURL);

      catsResponses.push(catsResponse.data);

      return catsResponses;
    })

    const preppedCategories = await Promise.all(newlyAddedCategories);

    preppedCategories.map(cat => {
      categories.push(cat[0]);
    })

    return categories;
  } else {
    return categories;
  }
}

function prepEvents(putEvents, events, tags, categories, venues, preppedVenues, preppedCats, baseURL, districtsAsTags) {
  preppedVenues.map(ven => {
    if (ven.venue) {
      ven.venue = ven.venue.replace(/&#(\d+);/g, function(match, dec) {
				return String.fromCharCode(dec)
      });

      ven.venue = _.unescape(ven.venue);

      return ven;
    }
  });

  preppedCats.map(cat => {
    if (cat.name) {
      cat.name = cat.name.replace(/&#(\d+);/g, function(match, dec) {
				return String.fromCharCode(dec)
      });

      cat.name = _.unescape(cat.name);

      return cat;
    }
  });

  let newlyPublishedEvents = [];
  let newlyPublishedEventIds = [];
  let publishedEvents = [];
  let unpublishedEvents = [];

  // separate published and unpublished events
  putEvents.map(event => {
    if (event.status === 'approved') {
      unpublishedEvents.push(event);
    } else {
      publishedEvents.push(event);
    }
  })

  //get newly published events
  let tagMap = {};
  let tagSlugs = [];
  let tagEventIdMap = {};

  tags.map(function(tag){
    tagMap[`${tag.name}`] = tag.id;
  })

  events.map(function(event){
    if (event.tags && event.tags != null) {
      event.tags.map(function(tag){
        tagSlugs.push(tag.slug);

        tagEventIdMap[tag.name] = event.id;
      })
    }
  })

  publishedEvents.map(function(pubEvent){
    if (tagSlugs.indexOf(`c${pubEvent.id}`) === -1 || !tagMap[`c${pubEvent.id}`]) {
      newlyPublishedEvents.push(pubEvent);
      newlyPublishedEventIds.push(pubEvent.id);
    }
  })

  // prep events for insert
  let catMap = [];
  let venueMap = [];
  let catCountMap = {};
  let venueCountMap = {};

  preppedVenues.map(venue => {
    venueMap[`${venue.venue}`] = venue.id;
    venueCountMap[venue.venue] = venue.count;
  })

  preppedCats.map(cat => {
    catMap[`${cat.name}`] = cat.id;
    catCountMap[cat.name] = cat.count;
  })

  let fields = [
    'title',
    'description',
    'start_date',
    'end_date',
    'venue',
    'categories',
    'tags',
    'image',
    'website',
    'sticky',
    'featured'
  ];

  publishedEvents.map(event => {

    event.venue = venueMap[event.venue_name];
    event.image = event.url_logo;
    event.website = event.url;

    let cats = [];

    if (event.tags && event.tags.length) {
      event.tags.map(function(tag){
        if (catMap[`${tag}`]) {
          cats.push(catMap[`${tag}`]);
        } else {
          cats.push(tag);
        }
      })
    }

    if (districtsAsTags && event.districts && event.districts.length) {
      event.districts.map(district => {
        if (catMap[`${district}`]) {
          cats.push(catMap[`${district}`]);
        } else {
          cats.push(district);
        }
      })
    }

    event['categories'] = cats;
    event['tags'] = [];

    console.log(tagMap);

    if (tagMap[`c${event.id}`]) {
      event['tags'].push(tagMap[`c${event.id}`]);
    } else {
      event['tags'].push(`c${event.id}`);
    }

    if (event.start_date != null && event.start_date != '') {
      event.start_date = event.start_date.slice(0, 19);
    }

    if (event.end_date != null && event.end_date != '') {
      event.end_date = event.end_date.slice(0, 19);
    }

    if (event.start_date == null || event.start_date === '') {
      event.start_date = `${event.end_date.slice(0, 11)}00:01:00`;
    }

    if (event.end_date == null || event.end_date === '') {
      event.end_date = `${event.start_date.slice(0, 11)}11:59:00`;
    }

    event.method = tagMap[`c${event.id}`] ? 'put' : 'post';

    event.requestParams = {};

    fields.map(field => {
      if (event[field] && event[field] != null && event[field] != undefined) {
        event.requestParams[`${field}`] = `${event[field]}`;
      }
    })
    console.log(event);

    if (event.method === 'post') {
      putEvent(event, baseURL);
    } else {
      event.wp_id = tagEventIdMap[`c${event.id}`];
      deleteReputEvent(event, baseURL);
    }

  })

  return {success: true};
}

async function putEvent(event, baseURL) {
  console.log(new Date());
  console.log(event);
    let putPostEventRequest = {
      "baseURL": baseURL,
      "method": event.method,
      "auth": {
        "username": "support@getchivvy.com",
        "password": "ww@%V2Yz@Me*!87ILhDz6IxX"
      },
      "url": `/events?id=10`,
      "data": event.requestParams,
    };

    console.log(putPostEventRequest);

    const eventResponse = await axios.request(putPostEventRequest);

    return eventResponse;
}

async function deleteReputEvent(event, baseURL) {
  const deleteEventRequest = {
    "baseURL": baseURL,
    "method": 'delete',
    "auth": {
      "username": "support@getchivvy.com",
      "password": "ww@%V2Yz@Me*!87ILhDz6IxX"
    },
    "url": `/events/${event.wp_id}`,
  }
  const deleteResponse = await axios.request(deleteEventRequest);

  console.log(deleteResponse);

  if (deleteResponse.data.status === 'trash') {
    const reputEventRequest = {
      "baseURL": baseURL,
      "method": 'post',
      "auth": {
        "username": "support@getchivvy.com",
        "password": "ww@%V2Yz@Me*!87ILhDz6IxX"
      },
      "url": `/events?id=10`,
      "data": event.requestParams,
    };

    const reputResponse = await axios.request(reputEventRequest)

    return {success: true};
  }
}

async function wordpressExport(putEvents, baseURL, districtsAsTags) {
  let events = await getClientData('events', baseURL);
  let tags = await getClientData('tags', baseURL);
  let categories = await getClientData('categories', baseURL);
  let venues = await getClientData('venues', baseURL);

  console.log(events);

  let preppedVenues = await prepVenues(putEvents, baseURL, districtsAsTags, venues, events, tags);
  let preppedCats = await prepCats(putEvents, baseURL, districtsAsTags, categories, events, tags);

  if (preppedVenues.length && preppedCats.length) {
    const eventsResponse = prepEvents(putEvents, events, tags, categories, venues, preppedVenues, preppedCats, baseURL, districtsAsTags);

    if (eventsResponse.success) {
      return {success: true};
    } else {
      return {success: false};
    }
  }
  // // Remove previously put events now unpublished
  // let eventsToRemoveTagIds = [];
  // let idsToRemove = [];
  // let removedCatsIds = [];
  // let removedCatsIdsCounts = {};
  // let removedVenuesIds = [];
  // let removedVenuesIdsCounts = {};
  // let removedTagsIds = [];
  //
  // console.log(unpublishedEvents);
  // console.log(tagMap);
  //
  // unpublishedEvents.map(event => {
  //   if (tagMap.hasOwnProperty(`c${event.id}`) == true) {
  //     idsToRemove.push(tagMap[`c${event.id}`]);
  //   }
  // })
  //
  // events.map(event => {
  //   if (idsToRemove.indexOf(event.id) >= 0) {
  //     if (event.categories && event.categories.length) {
  //       event.categories.map(cat => {
  //         removedCatsIds.push(cat.id);
  //       })
  //     }
  //
  //     removedVenuesIds.push(event.venue.id);
  //
  //     let regex = RegExp('^c[0-9]+');
  //
  //     if (event.tags && event.tags.length) {
  //       event.tags.map(tag => {
  //         if (regex.test(tag.name)) {
  //           removedTagsIds.push(tagMap[tag.name]);
  //         }
  //       })
  //     }
  //   }
  // });
  //
  // removedCatsIds.map(id => {
  //   removedCatsIdsCounts[id] = (removedCatsIdsCounts[id] || 0)+1;
  // });
  //
  // removedVenuesIds.map(id => {
  //   removedVenuesIdsCounts[id] = (removedVenuesIdsCounts[id] || 0)+1;
  // });
  //
  // idsToRemove.map(async id => {
  //   let removeRequest = {
  //     "baseURL": baseURL,
  //     "method": "delete",
  //     "auth": {
  //       "username": 'support@getchivvy.com',
  //       "password": 'ww@%V2Yz@Me*!87ILhDz6IxX'
  //     },
  //     "url": `/events/${id}`
  //   };
  //
  //   const removeResponse = await axios.request(removeRequest);
  // })

}

export default wordpressExport;
