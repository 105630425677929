import React, { Component } from 'react';

import './fileDropTargetStyles.css';

class FileDropTarget extends Component {
	state = {
		dragging: false,
	};

	handleDrop = e => {
		e.preventDefault();
		this.setState({ dragging: false });
		this.props.handleFileSelection(e.dataTransfer.files);
	};

	handleDragOver = e => {
		e.preventDefault();
		if (!this.state.dragging) this.setState({ dragging: true });
	};

	handleDragEnd = e => {
		e.preventDefault();
		this.setState({ dragging: false });
	};

	handleClick = () => {
		this.inputElement.click();
	};

	handleFilesSelected = e => {
		this.props.handleFileSelection(e.target.files);
	};

	render() {
		return (
			<div
				className={`file-drop-target ${this.state.dragging ? 'dragging' : ''}`}
				onDrop={this.handleDrop}
				onDragOver={this.handleDragOver}
				onDragLeave={this.handleDragEnd}
				onClick={this.handleClick}
			>
				<input
					type="file"
					id="file-selector"
					onChange={this.handleFilesSelected}
					multiple
					ref={input => (this.inputElement = input)}
				/>
				<p>Drag & Drop File (Or Click to Select File)</p>
			</div>
		);
	}
}

export default FileDropTarget;
