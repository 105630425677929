import React, { Component } from 'react';
import {
	Modal,
	Segment,
	Button,
	Icon,
	Visibility,
	Grid,
	Checkbox,
	Label,
} from 'semantic-ui-react';
import _ from 'lodash';
import logo from '../../assets/logo.svg';
import {
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
	EmailIcon,
	EmailShareButton,
} from 'react-share';
import moment from 'moment';
import VisibilitySensor from 'react-visibility-sensor';
import ReactVirtualized from 'react-virtualized';
import events from '../EventManage/events.js';
import tags from '../EventManage/tags.js';
import './producerEventLiveStyles.css';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import API from '../../services/API';

export default class ProducerEventLive extends Component {
	state = {
		events: [],
	};

	async componentDidMount() {
		try {
			const eventsResponse = await API.get('/events', {
				params: {
					status: 'producer-published',
				},
			});

			eventsResponse.data.content.map(event => {
				let eventTags = [];
				if (event.tags != null && event.tags != '') {
					eventTags = event.tags.split('|');
				}
				event.tags = eventTags;

				event.startUnix = new Date(event.start_date).getTime();
			});

			this.setState({
				events: eventsResponse.data.content,
			});
		} catch (error) {
			console.log(error);
		}
	}

	render() {
		return <EventList events={this.state.events} />;
	}
}

class EventList extends Component {
	state = {
		events: this.props.events,
		page: 1,
		selected: [],
		open: false,
		contextRef: null,
	};

	shouldComponentUpdate = (nextProps, nextState) => {
		return !_.isEqual(nextState, this.state);
	};

	componentDidUpdate = () => {
		//window.scrollTo(0, 0);
	};

	componentWillReceiveProps = nextProps => {
		//window.scrollTo(0, 0);
		this.setState({
			searchTerm: nextProps.searchTerm,
			events: nextProps.events,
		});
	};

	scrollUpdate = (e, { calculations }) => {
		if (calculations.percentagePassed > 0.5) {
			this.setState({
				page: this.state.page + 1,
			});
		}
	};

	handleContextRef = contextRef => {
		this.setState({ contextRef });
	};

	toggleEventSelected = (event, e, data) => {
		let selected = _.cloneDeep(this.state.selected);

		if (!data.checked) {
			selected.push(event);
		} else {
			_.pullAllWith(selected, [event], _.isEqual);
		}

		this.setState({
			selected,
		});
	};

	downloadCSV = selected => {
		let events = selected
			? _.cloneDeep(this.state.selected)
			: _.cloneDeep(this.state.events);

		events.map(function(event) {
			if (event.tags && event.tags !== null) {
				event['tags'] = event.tags.join(',');
			}
		});

		let fields = [
			'title',
			'description',
			'start_date',
			'end_date',
			'url_logo',
			'url',
			'name',
			'address',
			'city',
			'state',
			'zip',
			'tags',
			'date_created',
			'id',
		];

		let replacer = function(key, value) {
			return value === null ? '' : value;
		};

		let csv = events.map(function(row) {
			return fields
				.map(function(fieldName) {
					let value =
						fieldName === 'start_date' ||
						fieldName === 'end_date' ||
						fieldName === 'date_created'
							? wipeZT(row[fieldName])
							: row[fieldName];
					value = JSON.stringify(value);
					value = value.replace(/\\n/g, '');
					value = value.replace(/\\r/g, '');

					return value;
				})
				.join(',');
		});

		csv.unshift(fields.join(',')); // add header column

		csv.unshift('data:text/csv;charset=utf-8,');

		csv = csv.join('\n');

		var encodedUri = encodeURI(csv);

		var link = document.createElement('a');

		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'chivvyLiveEvents.csv');
		document.body.appendChild(link); // Required for FF

		link.click();

		//window.open(encodeURI(csv));
	};

	render() {
		const events = this.state.events;

		const { contextRef } = this.state;

		let sorted = [...events].sort((a, b) => a.startUnix - b.startUnix);

		let eventDivs = sorted.map((event, index) => {
			return (
				<EventItem
					key={index}
					event={event}
					toggleSelected={this.toggleEventSelected}
					selected={this.state.selected}
				/>
			);
		});

		let chunked = _.chunk(eventDivs, 20);

		let rendered = chunked.slice(0, this.state.page);

		rendered[rendered.length - 1] = (
			<Visibility onUpdate={this.scrollUpdate}>
				{rendered[rendered.length - 1]}
			</Visibility>
		);

		let sensor = (
			<VisibilitySensor
				scrollCheck={true}
				scrollDelay={100}
				intervalCheck={false}
				partialVisibility={true}
				containment={document.getElementById('results')}
				onChange={isVisible => {
					let msg = isVisible ? 'visible!' : 'hidden';
					if (isVisible && this.state.page < chunked.length) {
						this.setState({
							page: this.state.page + 1,
						});
					}
				}}
			>
				<div className="sensor" />
			</VisibilitySensor>
		);

		rendered.push(sensor);

		let list = (
			<Visibility
				id="visibility"
				onUpdate={this.scrollUpdate}
				context={contextRef}
			>
				<Segment.Group id="event-list-container">{rendered}</Segment.Group>
			</Visibility>
		);

		let csvDownload = (
			<Button
				className="exportButton"
				icon={true}
				labelPosition="left"
				onClick={() => this.downloadCSV(false)}
			>
				<Icon name="table" />
				download csv
			</Button>
		);

		let csvDownloadSelected = (
			<Button
				className="exportButton"
				icon={true}
				labelPosition="left"
				onClick={() => this.downloadCSV(true)}
			>
				<Icon className="table" />
				download csv selected
			</Button>
		);

		let noEventsAlert = (
			<Button fluid disabled color="orange">
				Whoops ... There are no live events. Go to the Manage page and publish
				events to see them here.
			</Button>
		);

		return (
			<Grid className="EventList" id="event-list" ref={this.handleContextRef}>
				<Grid.Row className="sticky">
					<Grid.Column width={8} />
					<Grid.Column floated="right" width={8}>
						<div className="liveControlButtons">
							{csvDownload}
							{csvDownloadSelected}
						</div>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>{list}</Grid.Row>
			</Grid>
		);
	}
}

class EventItem extends Component {
	state = {
		event: this.props.event,
		tags: this.props.event.tags || [],
		selected: [],
	};

	componentWillReceiveProps = nextProps => {
		if (!_.isEqual(nextProps.event, this.state.event)) {
			this.setState({
				event: nextProps.event,
				tags: nextProps.event.tags || [],
			});
		}
	};

	handleCheckboxClick = (eventData, e, data) => {
		this.props.toggleSelected(eventData, e, data);
	};

	render() {
		const event = this.state.event;
		const tags = this.state.event.tags;
		const selected = this.props.selected;

		return (
			<Segment className="EventItem producerLive">
				<Grid>
					<Grid.Row>
						<Grid.Column floated="left" width={12} className="event-info">
							<Title title={event.title} url={event.url} />
							<Venue name={event.name} />
							<DateInfo date={formatDate(event.start_date, event.end_date)} />
							<Taglist tags={tags} />
						</Grid.Column>
						<Grid.Column
							floated="right"
							width={4}
							className="eventControlsImageContainer"
						>
							<div className="event-checkbox">
								<Grid.Row>
									<FacebookShareButton
										url={event.url}
										quote={`${event.title} || ${event.description}`}
									>
										<Button circular color="facebook" icon>
											<Icon name="facebook f" />
										</Button>
									</FacebookShareButton>
									<TwitterShareButton url={event.url} title={event.title}>
										<Button circular color="twitter" icon>
											<Icon name="twitter" />
										</Button>
									</TwitterShareButton>
									<EmailShareButton url={event.url} subject={event.title}>
										<Button circular icon>
											<Icon name="mail" />
										</Button>
									</EmailShareButton>
									<Checkbox
										className="liveCheckbox"
										checked={_.findIndex(selected, e => e.id === event.id) > -1}
										onClick={this.handleCheckboxClick.bind(this, event)}
									/>
								</Grid.Row>
								<Grid.Row className="eventImageRow">
									<div className="event-img">
										<Image
											url={event.urlLogo}
											editImage={this.editEventImage}
										/>
									</div>
								</Grid.Row>
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="eventDescriptionRow">
						<Description text={event.description} />
					</Grid.Row>
				</Grid>
			</Segment>
		);
	}
}

class Title extends Component {
	render() {
		return (
			<div>
				<h1 className="Title">{this.props.title}</h1>
				<div className="event-url-container">
				<Label className='itemLabel'>Link: </Label>
				<a className="event-url" target="_blank" href={this.props.url}>
					{this.props.url}
				</a>
				</div>
			</div>
		);
	}
}

class Venue extends Component {
	render() {
		return <div><Label className='itemLabel'>Venue: </Label><p className="VenueName">{this.props.name}</p></div>
	}
}

class DateInfo extends Component {
	render() {
		return <div><Label className='itemLabel'>When: </Label><p className="Date">{this.props.date}</p></div>
	}
}

class Image extends Component {
	state = {
		url: this.props.url,
		isMouseInside: false,
	};

	render() {
		return (
			<div className="event-image-container">
				<img src={this.state.url} />
			</div>
		);
	}
}

class Taglist extends Component {
	render() {
		let tags = this.props.tags || [];

		tags = tags.sort();

		let tagDivs = tags.map((tag, index) => {
			return <Tag key={index} text={tag} />;
		});

		return (
			<div className="Taglist">
				<Label>Tags: </Label>
				{tagDivs}
			</div>
		);
	}
}

class Tag extends Component {
	render() {
		return <Label color="pink">{this.props.text}</Label>;
	}
}

class Description extends Component {
	render() {
		return <div><Label className='itemLabel'>Description: </Label><p className="Description">{this.props.text}</p></div>;
	}
}

function wipeZT(date) {
	let newDate;

	if (date != null && date != '') {
		newDate = date.substr(0, 19);
		newDate = newDate.replace('T', ' ');

		return newDate;
	} else {
		return null;
	}
}

function formatDate(start, end) {
	return `${moment(start).utc().format('MM/DD/YYYY h:mma')} to ${moment(end).utc().format('MM/DD/YYYY h:mma')}`;
}
