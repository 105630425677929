import { ApiBaseStore } from '../common/apiBaseStore';
import { StoreCollection } from '../../common/StoreCollection';
import { request } from '../../../services/API';

/**
 * ApiEventCreateStore
 */
export class ApiEventCreateStore extends ApiBaseStore {
	/**
	 * Creates a new event using the api.
	 *
	 * @param {{}} eventData
	 */
	request(eventData) {
		if (!eventData) {
			throw new Error('Event Create: The event data is required.');
		}

		this.setPending(false);

		request({
			method: 'post',
			url: '/v2/events',
			data: {
				events: [eventData],
			}
		}).then(
			(data) => {
				this.setFulfilled(data, false);
			},
			(eventCreateError) => {
				this.setRejected(eventCreateError, false);
			}
		);
	}
}

export default new StoreCollection(ApiEventCreateStore);
