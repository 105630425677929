import React, { Component } from 'react';
import { Button, Form, Segment, Message } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

import './styles.css';
import API from '../../services/API';

class ForgotPasswordForm extends Component {
	state = {
		email: '',
		code: '',
		newPassword: '',
		newPasswordConfirm: '',
		codeSent: false,
	};

	handleFieldChange = (property, event) => {
		const value = event.target.value;

		this.setState({
			[property]: value,
		});
	};

	sendCode = () => {
		const { email } = this.state;
		API.post('/auth/passwordReset', {
			username: email
		}).then((forgotPasswordResponse) => {
			if (forgotPasswordResponse.data.success) {
				this.setState({
					codeSent: true,
				});
			} else {
				this.setState({
					codeSentError: forgotPasswordResponse.data.message,
				})
			}
		});
	};

	handleNewPassword = () => {
		const { email, code, newPassword } = this.state;
		API.post('/auth/passwordReset/response', {
			username: email,
			code,
			newPassword
		}).then((changePasswordResponse) => {
			if (changePasswordResponse.data.success) {
				this.setState({
					passwordChanged: true,
				});
			} else {
				this.setState({
					newPasswordError: changePasswordResponse.data.message,
				})
			}
		});
	};

	renderForm = () => {
		if (this.state.passwordChanged) {
			return <Redirect to="/" />;
		}

		if (this.state.codeSent) {
			return (
				<Segment>
					<Form.Input
						fluid
						value={this.state.code}
						placeholder="Verification Code"
						type="text"
						onChange={this.handleFieldChange.bind(this, 'code')}
					/>
					<Form.Input
						fluid
						value={this.state.newPassword}
						placeholder="New Password"
						type="password"
						onChange={this.handleFieldChange.bind(this, 'newPassword')}
					/>
					<Form.Input
						fluid
						value={this.state.newPasswordConfirm}
						placeholder="Confirm New Password"
						type="password"
						onChange={this.handleFieldChange.bind(this, 'newPasswordConfirm')}
					/>
					{this.state.newPasswordError && (
						<Message negative>{this.state.newPasswordError}</Message>
					)}
					<Button
						disabled={
							!this.state.code ||
							!this.state.newPassword ||
							!this.state.newPasswordConfirm ||
							this.state.newPassword !== this.state.newPasswordConfirm
						}
						color="red"
						fluid
						size="large"
						onClick={this.handleNewPassword}
					>
						Change Password
					</Button>
				</Segment>
			);
		} else {
			return (
				<Segment>
					<Form.Input
						fluid
						value={this.state.email}
						icon="user"
						iconPosition="left"
						placeholder="E-mail address"
						onChange={this.handleFieldChange.bind(this, 'email')}
					/>
					{this.state.codeSentError && (
						<Message negative>{this.state.codeSentError}</Message>
					)}
					<Button
						disabled={!this.state.email}
						color="red"
						fluid
						size="large"
						onClick={this.sendCode}
					>
						Send Verification Code
					</Button>
				</Segment>
			);
		}
	};

	render() {
		return (
			<div className="forgot-pw-form">
				<h1 className="center header">Forgot your password?</h1>
				<Form size="large">{this.renderForm()}</Form>
			</div>
		);
	}
}

export default ForgotPasswordForm;
