import { ApiBaseStore } from '../common/apiBaseStore';
import { StoreCollection } from '../../common/StoreCollection';
import { request } from '../../../services/API';

/**
 * ApiEventFacebookGetAllStore
 */
export class ApiEventFacebookGetAllStore extends ApiBaseStore {
	/**
	 * Gets the imported facebook events for the user's client from the api.
	 */
	request() {
		this.setPending();

		request({
			method: 'get',
			url: '/v2/events/facebook',
		}).then(
			(data) => {
				this.setFulfilled(data);
			},
			(eventsGetError) => {
				this.setRejected(eventsGetError);
			}
		);
	}
}

export default new StoreCollection(ApiEventFacebookGetAllStore);
