import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'unstated';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// axios.interceptors.response.use(function (response) {
// 	return response;
//   }, function (error) {

// 	const originalRequest = error.config;

// 	if (error.response.status === 401 && !originalRequest._retry) {

// 	  originalRequest._retry = true;

// 	  const refreshToken = window.localStorage.getItem('refreshToken');
// 	  return axios.post('http://localhost:8000/auth/refresh', { refreshToken })
// 		.then(({data}) => {
// 		  window.localStorage.setItem('token', data.token);
// 		  window.localStorage.setItem('refreshToken', data.refreshToken);
// 		  axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
// 		  originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
// 		  return axios(originalRequest);
// 		});
// 	}

// 	return Promise.reject(error);
//   });

ReactDOM.render(
	<Provider>
		<Router>
				<App />
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
