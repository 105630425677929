import React, { Component } from 'react';
import { Card, Button, Icon, Grid, Segment, Label } from 'semantic-ui-react';
import readXlsxFile from 'read-excel-file';
import _ from 'lodash';
import moment from 'moment';
import toastr from 'toastr';
import FileDropTarget from '../FileDropTarget';
import EventCard from '../EventCard';
import schema from './excelSchema';
import API from '../../services/API';

import './uploadFormStyles.css';
import templateData from './templateData.js';

class UploadForm extends Component {
	state = {
		events: [],
	};

	handleFileSelection = async fileList => {
		let events = [];
		for (var i = 0; i < fileList.length; i++) {
			const { rows, errors } = await readXlsxFile(fileList[i], { schema });
			if (errors.length) {
				console.log('Errors occurred while parsing Excel file:');
				console.log(errors);
				return;
			}
			events = events.concat(rows);
		}

		this.setState({ events });
	};

	downloadTemplate = () => {
		let blob = b64toBlob(templateData, 'binary');
    if(window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'ChivvyExcelTemplate.xlsx');
    }
    else{
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = 'ChivvyExcelTemplate.xlsx';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
	};

	uploadEvents = () => {
		this.props.uploading(true, this.putEvents());
	}

	putEvents = async () => {
		let events = _.cloneDeep(this.state.events);

		events.map(event => {
			function guid() {
				function s4() {
					return Math.floor((1 + Math.random()) * 0x10000)
						.toString(16)
						.substring(1);
				}
				return (
					s4() +
					s4() +
					'-' +
					s4() +
					'-' +
					s4() +
					'-' +
					s4() +
					'-' +
					s4() +
					s4() +
					s4()
				);
			}

			let venueInfo = {
				name: `${event.venue_name}`,
				address: `${event.venue_address}`,
				city: `${event.venue_city}`,
				state: `${event.venue_state}`,
				zip: `${event.venue_zip}`,
				lat: null,
				lon: null,
				venue_id: null,
			};

			event.venue = venueInfo;
			event.source = 'producer-upload';
			event.status = 'producer-uploaded';
			event.source_event_id = guid();
			if (event.tags != null && event.tags.length) {
				event.tags = event.tags.split(',');
				event.tags.map(tag => {
					tag = tag.trim();
				});
			}
			event.start_date = event.start_time != null && event.start_time.length ? formatDate(event.start_time) : '';
			event.end_date = event.end_time != null && event.end_time.length ? formatDate(event.end_time) : '';
			event.guest = 0;
			event.featured = 0;
			event.sticky = 0;
			event.exported = 0;
			event.import_id = null;

			delete event.venue_name;
			delete event.venue_address;
			delete event.venue_city;
			delete event.venue_state;
			delete event.venue_zip;
			delete event.start_time;
			delete event.end_time;
		});

		const toastrConfig = {
			preventDuplicates: false,
			timeOut: '5000',
			hideMethod: 'fadeOut',
			progressBar: false,
			extendedTimeOut: '1000',
			showEasing: 'swing',
			closeButton: true,
			hideEasing: 'linear',
			positionClass: 'toast-top-right',
			newestOnTop: false,
			showDuration: '300',
			debug: false,
			hideDuration: '1000',
			showMethod: 'fadeIn',
		};

		toastr.options = toastrConfig;

		if (!events.length) {
			toastr.warning('Nothing to upload ...', 'Whoops ...');
		} else {
			try {
				const eventsResponse = await API.post('/events', {
					events: events,
				});

				if (eventsResponse.data.success) {
					toastr.success('Events uploaded sucessfully ...', 'Woohoo!');
					this.setState({
						events: []
					}, this.props.uploading(false));
				}
			} catch (error) {
				console.log(error);

				toastr.error(`${error}`, 'Whoops ...');
			}
		}
	};

	render() {
		const cards = this.state.events.map((event, index) => (
			<EventCard key={index} {...event} />
		));

		return (
			<Grid centered columns={1} className="upload-container">
				<Grid.Row>
					<Grid.Column width={4} />
					<Grid.Column width={8}>
						<FileDropTarget
							handleFileSelection={this.handleFileSelection}
							className="uploadFileDrop"
						/>
					</Grid.Column>
					<Grid.Column width={4} />
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={5} />
					<Grid.Column width={6} id="uploadButtonColumn">
						{<Button color='pink' icon onClick={this.downloadTemplate}><Icon name='table whiteIcon' />Download Template</Button>}
						{this.state.events.length ? (
							<Button color="pink" inverted icon onClick={this.uploadEvents}>
								<Icon name="upload" />
								Upload Events
							</Button>
						) : null}
					</Grid.Column>
					<Grid.Column width={5} />
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Segment raised className="eventPreviewPane">
							<Label ribbon color="pink">
								Event List Preview
							</Label>
							{cards.length ? (
								<Card.Group className="item-list">{cards}</Card.Group>
							) : (
								<h5>Upload some events, then see a preview here ...</h5>
							)}
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

function formatDate(date) {
	return moment(date, 'MM/DD/YYYY HH:mm A')
		.format()
		.substr(0, 19)
		.replace('T', ' ');
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export default UploadForm;
