export default [
  {
    "count": 0,
    "value": "all",
    "filter": "",
    "display": "all"
  },
  {
    "count": 0,
    "field": "status",
    "selected": "false",
    "filter": "status:\"not reviewed\"",
    "value": "not reviewed",
    "display": "not reviewed"
  },
  {
    "count": 0,
    "field": "status",
    "selected": "false",
    "filter": "status: \"approved\"",
    "value": "approved",
    "display": "approved"
  },
  {
    "count": 0,
    "field": "status",
    "selected": "false",
    "filter": "status: \"rejected\"",
    "value": "rejected",
    "display": "rejected"
  }
]
