import React, { Component } from 'react';
import {
	Segment,
	Label,
	Button,
	Dropdown,
	Grid,
	Icon,
	List,
} from 'semantic-ui-react';
import API from '../../services/API';
import toastr from 'toastr';
import { Map, TileLayer, FeatureGroup, GeoJSON } from 'react-leaflet';
import leafletPip from '@mapbox/leaflet-pip';
import './districtManageStyles.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import _ from 'lodash';

export default class DistrictManage extends Component {
	state = {
		selected: [],
		districts: [],
		existingMapKey: [],
		districtsToAdd: [],
		allDistricts: [],
		saveNeeded: false,
	};

	async componentDidMount() {
		try {
			const clientDistrictsResponse = await API.get('/districts')

			const chivvyDistrictsResponse = await API.get('/districts/chivvy');

			let allDistricts = clientDistrictsResponse.data.content.concat(
				chivvyDistrictsResponse.data.content
			);

			let districtsToAdd = [];
			let clientDistrictNames = [];

			clientDistrictsResponse.data.content.map(function(district) {
				clientDistrictNames.push(district.district_name);
			});

			chivvyDistrictsResponse.data.content.map(function(district) {
				if (clientDistrictNames.indexOf(district.district_name) === -1) {
					districtsToAdd.push(district);
				}
			});

			this.setState({
				districts: clientDistrictsResponse.data.content,
				existingMapKey: clientDistrictsResponse.data.content,
				districtsToAdd: districtsToAdd,
				allDistricts: allDistricts,
			});
		} catch (error) {
			console.log(error);
		}
	}

	handleExistingMapClick = selected => {
		this.setState({
			selected: selected,
		});
	};

	addDistrict = (event, { value }) => {
		let allDistricts = _.cloneDeep(this.state.allDistricts);
		let districtsToAdd = _.cloneDeep(this.state.districtsToAdd);
		let districts = _.cloneDeep(this.state.districts);

		allDistricts.map(function(district) {
			if (district.district_name === value) {
				districts.push(district);
			}
		});

		districtsToAdd = districtsToAdd.filter(
			district => district.district_name !== value
		);

		this.setState({
			districts: districts,
			districtsToAdd: districtsToAdd,
			existingMapKey: districts,
			saveNeeded: true,
		});
	};

	deleteDistrict = id => {
		let districts = _.cloneDeep(this.state.districts);

		let updatedDistricts = districts.filter(district => district.id != id);

		let updatedSelected = _.cloneDeep(this.state.selected);

		updatedSelected = updatedSelected.filter(
			district => district.properties.id != id
		);

		let districtsToAdd = _.cloneDeep(this.state.districtsToAdd);

		let removedDistrict;
		districts.map(function(district) {
			if (district.id === id) {
				removedDistrict = district;
			}
		});

		districtsToAdd.push(removedDistrict);

		this.setState({
			districts: updatedDistricts,
			selected: updatedSelected,
			existingMapKey: updatedDistricts,
			districtsToAdd: districtsToAdd,
			saveNeeded: true,
		});
	};

	saveNotNeeded = () => {
		this.setState({
			saveNeeded: false,
		});
	};

	saveDistricts = () => {
		let districts = _.cloneDeep(this.state.districts);

		if (districts.length) {
			this.putDistricts(districts);
		} else {
			const toastrConfig = {
				preventDuplicates: false,
				timeOut: '5000',
				hideMethod: 'fadeOut',
				progressBar: false,
				extendedTimeOut: '1000',
				showEasing: 'swing',
				closeButton: true,
				hideEasing: 'linear',
				positionClass: 'toast-top-right',
				newestOnTop: false,
				showDuration: '300',
				debug: false,
				hideDuration: '1000',
				showMethod: 'fadeIn',
			};

			toastr.options = toastrConfig;
			toastr.warning(
				'No districts to save. Chivvy curators must map out districts in order to find events ...',
				'Drat ...'
			);
		}
	};

	putDistricts = async districts => {
		try {
			if (districts.length) {
				const districtsResponse = await API.post('/districts', {
					districts: districts,
				});

				if (districtsResponse.data.success) {
					const toastrConfig = {
						preventDuplicates: false,
						timeOut: '5000',
						hideMethod: 'fadeOut',
						progressBar: false,
						extendedTimeOut: '1000',
						showEasing: 'swing',
						closeButton: true,
						hideEasing: 'linear',
						positionClass: 'toast-top-right',
						newestOnTop: false,
						showDuration: '300',
						debug: false,
						hideDuration: '1000',
						showMethod: 'fadeIn',
					};

					toastr.options = toastrConfig;
					toastr.success('Districts saved sucessfully ...', 'Woohoo!');

					this.saveNotNeeded();
				} else {
					toastr.error(
						`${districtsResponse.data.message}`,
						'Darn, events not saved ...'
					);
				}
			}
		} catch (error) {
			console.log(error);

			toastr.error(`${error}`, 'Whoops, events not saved ...');
		}
	};

	render() {
		let selected = this.state.selected;

		let selectedList = selected.map(district => (
			<List.Item key={district.properties.id}>
				<Button as="div" labelPosition="left" floated="right" size="mini">
					<Label size="mini" basic>
						{district.properties.name}
					</Label>
					<Button
						key={district.properties.id}
						compact
						size="mini"
						icon
						color="red"
						onClick={this.deleteDistrict.bind(this, district.properties.id)}
					>
						<Icon name="trash alternate" />
						delete district
					</Button>
				</Button>
			</List.Item>
		));

		let noDistrictsStyle = {
			fontStyle: 'italic',
		};

		let noDistrictsSelected = selected.length ? (
			<div />
		) : (
			<p style={noDistrictsStyle}>click to select districts</p>
		);

		let districtOptions = [];
		this.state.districtsToAdd.map(district => {
			districtOptions.push({
				key: `${district.id}`,
				text: `${district.district_name}`,
				value: `${district.district_name}`,
			});
		});

		return (
			<Grid className="districtsContainer" divided={true}>
				<Grid.Row>
					<Grid.Column className="menuContainer">
						<Grid.Row>
							<div className="districtsSaveButton">
								<Button
									icon
									fluid
									inverted
									color="pink"
									onClick={this.saveDistricts}
								>
									<Icon name="save" />
									Save
								</Button>
								{this.state.saveNeeded ? (
									<Label color="yellow" pointing>
										Make Sure to Save your Changes!
									</Label>
								) : null}
							</div>
						</Grid.Row>
						<Grid.Row>
							<div className="menuContainerChild">
								<Segment floated="right">
									<Label attached="top">Add New Districts</Label>
									<Dropdown
										button
										className="icon"
										floating
										labeled
										icon="object group"
										options={districtOptions}
										selectOnBlur={false}
										text="Add Districts"
										scrolling
										direction="left"
										closeOnChange={false}
										onChange={this.addDistrict}
									/>
								</Segment>
							</div>
						</Grid.Row>
						<Grid.Row>
							<div className="menuContainerChild">
								<Segment floated="right">
									<Label attached="top">Selected Districts</Label>
									{noDistrictsSelected}
									<List>{selectedList}</List>
								</Segment>
							</div>
						</Grid.Row>
					</Grid.Column>
					<Grid.Column className="existingMapContainer">
						<ExistingMap
							selected={this.state.selected}
							handleClick={this.handleExistingMapClick}
							districts={this.state.districts}
							key={this.state.existingMapKey}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

class ExistingMap extends Component {
	state = {
		lat: 35.472942,
		lng: -97.517104,
		zoom: 12,
		districts: this.props.districts,
		selected: this.props.selected,
		handleClick: this.props.handleClick,
		existingMapKey: this.props.existingMapKey,
	};

	layerRef = React.createRef();

	handleClick = e => {
		let selectedLayers = leafletPip.pointInLayer(
			e.latlng,
			this.layerRef.current.leafletElement,
			false
		);
		let selected = _.cloneDeep(this.state.selected);

		if (selectedLayers.length) {
			selectedLayers.map(function(layer) {
				selected.push(layer.feature);
			});
		}

		this.state.handleClick(selected);
	};

	render() {
		let features = this.state.districts.map(function(polygon) {
			return {
				type: 'Feature',
				properties: {
					name: polygon.district_name,
					id: polygon.id,
				},
				geometry: polygon.geo_json,
			};
		});

		const position = [this.state.lat, this.state.lng];
		return (
			<Map
				className="existingMap"
				center={position}
				zoom={this.state.zoom}
				onClick={this.handleClick}
			>
				<TileLayer
					attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<FeatureGroup>
					<GeoJSON
						data={features}
						ref={this.layerRef}
						key={this.state.existingMapKey}
					/>
				</FeatureGroup>
			</Map>
		);
	}
}
