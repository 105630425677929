import React, { Component } from 'react';
import {
	Form,
	Segment,
	Label,
	Input,
	Button,
	Dropdown,
	Divider,
} from 'semantic-ui-react';
import DateTimePicker from 'react-datetime-picker';
import states from '../../assets/states.js';
import API from '../../services/API';
import toastr from 'toastr';
import './eventFormStyles.css';

export default class EventForm extends Component {
	state = {
		title: '',
		description: '',
		url: '',
		url_logo: '',
		startDate: null,
		endDate: null,
		tags: [],
		venue_id: null,
		venueName: '',
		venueAddress: '',
		venueCity: '',
		venueState: '',
		venueZip: '',
		tagOpts: [],
		venueOpts: [],
	};

	async componentDidMount() {
		try {
			const tagsResponse = await API.get('/tags');

			const venuesResponse = await API.get('/venues');

			let tagOpts = tagsResponse.data.content.map(tag => {
				return { text: tag.tag, value: tag.tag, key: tag.id };
			});

			let venueOpts = [];
			venuesResponse.data.content.map(venue => {
				if (venue.name) {
					venueOpts.push({
						key: `${venue.id}`,
						value: venue.id,
						text: `${venue.name}`,
					});
				}
			});

			this.setState({
				tagOpts: tagOpts,
				venueOpts: venueOpts,
			});
		} catch (error) {
			console.log(error);
		}
	}

	handleFieldChange = (name, e, { value }) => {
		this.setState({
			[name]: value,
		});
	};

	handleAddition = (e, { value }) => {
		this.setState(prevState => ({
			tagOpts: [{ text: value, value }, ...prevState.tagOpts],
		}));
	};

	handleDateChange = (property, date) => {
		this.setState({
			[property]: date,
		});
	};

	// handleVenueChange = (venue) => {
	// 	console.log(venue.target);
	// }

	handleSubmit = () => {
		const {
			title,
			description,
			url,
			url_logo,
			startDate,
			endDate,
			tags,
			venue_id,
			venueName,
			venueAddress,
			venueCity,
			venueState,
			venueZip,
		} = this.state;

		console.log(venue_id);

		let event = {
			title: title,
			description: description,
			url: url,
			url_logo: url_logo,
			start_date: startDate,
			end_date: endDate,
			tags: tags,
			venue_id: venue_id,
			venueName: venueName,
			venueAddress: venueAddress,
			venueCity: venueCity,
			venueState: venueState,
			venueZip: venueZip,
		};

		console.log(event);

		function guid() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000)
					.toString(16)
					.substring(1);
			}
			return (
				s4() +
				s4() +
				'-' +
				s4() +
				'-' +
				s4() +
				'-' +
				s4() +
				'-' +
				s4() +
				s4() +
				s4()
			);
		}

		let venueInfo = {
			venue_id: event.venue_id,
			name: `${event.venueName}`,
			address: `${event.venueAddress}`,
			city: `${event.venueCity}`,
			state: `${event.venueState}`,
			zip: `${event.venueZip}`,
			lat: null,
			lon: null,
		};

		console.log(venueInfo);

		event.venue = venueInfo;
		event.source = 'producer-upload';
		event.status = 'producer-uploaded';
		event.sourceEventId = guid();
		event.start_date = buildDateTimeString(event.start_date);
		event.end_date = buildDateTimeString(event.end_date);
		event.guest = 0;
		event.featured = 0;
		event.sticky = 0;
		event.exported = 0;
		event.import_id = null;

		delete event.venue_id;
		delete event.venueName;
		delete event.venueAddress;
		delete event.venueCity;
		delete event.venueState;
		delete event.venueZip;

		let events = [];
		events.push(event);

		this.putEvents(events);
	};

	putEvents = async events => {
		const toastrConfig = {
			preventDuplicates: false,
			timeOut: '5000',
			hideMethod: 'fadeOut',
			progressBar: false,
			extendedTimeOut: '1000',
			showEasing: 'swing',
			closeButton: true,
			hideEasing: 'linear',
			positionClass: 'toast-top-right',
			newestOnTop: false,
			showDuration: '300',
			debug: false,
			hideDuration: '1000',
			showMethod: 'fadeIn',
		};

		toastr.options = toastrConfig;

		if (
			events[0].title !== '' ||
			events[0].start_date !== '' ||
			(events[0].end_date !== '' &&
				(events[0].venue.venue_id !== null ||
					(events[0].venue.name !== '' ||
						events[0].venue.address !== '' ||
						events[0].venue.city !== '' ||
						events[0].venue.state !== '' ||
						events[0].venue.zip !== '')))
		) {
			try {
				const eventsResponse = await API.post('/events', {
					events: events,
				});

				if (eventsResponse.data.success) {
					toastr.success('Event uploaded sucessfully ...', 'Woohoo!');
					this.setState(
						{
							events: [],
						},
						this.clearForm()
					);
				}
			} catch (error) {
				console.log(error);

				toastr.error(`${error}`, 'Whoops ...');
			}
		} else {
			toastr.warning(
				'Make sure your event has a title, start date, end date, and all venue info ...',
				'One Sec ...'
			);
		}
	};

	clearForm = () => {
		this.setState({
			title: '',
			description: '',
			url: '',
			startDate: null,
			endDate: null,
			tags: [],
			venue_id: null,
			venueName: '',
			venueAddress: '',
			venueCity: '',
			venueState: '',
			venueZip: '',
			url_logo: '',
		});
	};

	render() {
		const {
			title,
			description,
			url,
			url_logo,
			startDate,
			endDate,
			tags,
			venue_id,
			venueName,
			venueAddress,
			venueCity,
			venueState,
			venueZipCode,
			tagOpts,
			venueOpts,
		} = this.state;

		return (
			<div className="formContainer">
				<Segment raised>
					<Label color="pink" ribbon>
						Event Details
					</Label>
					<Form>
						<Form.Field>
							<label>Image Link</label>
							<Input
								label="http://"
								placeholder="www.event-site.com/event-image.png"
								value={url_logo}
								name="url_logo"
								onChange={this.handleFieldChange.bind(this, 'url_logo')}
							/>
						</Form.Field>
						<Form.Input
							label="Title"
							placeholder="Title"
							value={title}
							onChange={this.handleFieldChange.bind(this, 'title')}
						/>
						<Form.TextArea
							label="Description"
							placeholder="Enter event description..."
							value={description}
							onChange={this.handleFieldChange.bind(this, 'description')}
						/>
						<Form.Field>
							<label>Link</label>
							<Input
								label="http://"
								placeholder="www.event-site.com"
								value={url}
								onChange={this.handleFieldChange.bind(this, 'url')}
							/>
						</Form.Field>
					</Form>

					<div className="flexed around">
						<div className="custom-field">
							<label>Start Date</label>
							<DateTimePicker
								value={startDate}
								disableClock={true}
								onChange={this.handleDateChange.bind(this, 'startDate')}
							/>
						</div>

						<div className="custom-field">
							<label>End Date</label>
							<DateTimePicker
								value={endDate}
								disableClock={true}
								onChange={this.handleDateChange.bind(this, 'endDate')}
							/>
						</div>
					</div>

					<div className="custom-field">
						<label>Tags</label>
						<Dropdown
							fluid
							multiple
							search
							selection
							value={tags}
							allowAdditions
							additionLabel="Custom Tag: "
							onAddItem={this.handleAddition}
							placeholder="Select tags for event"
							options={tagOpts}
							onChange={this.handleFieldChange.bind(this, 'tags')}
						/>
					</div>
					<Label color="pink" ribbon>
						Venue Details
					</Label>
					<Form>
						<Label color="teal">Select a Preexisting Venue</Label>
						<Dropdown
							fluid
							clearable
							selectOnBlur={false}
							search
							scrolling
							selection
							value={venue_id}
							placeholder="Select venue"
							options={venueOpts}
							onChange={this.handleFieldChange.bind(this, 'venue_id')}
						/>
						<Divider horizontal>Or</Divider>
						<Label color="teal">If Venue not Found, Create it Below</Label>
						<Form.Group widths="equal">
							<Form.Input
								label="Name"
								placeholder="Venue Name"
								value={venueName}
								onChange={this.handleFieldChange.bind(this, 'venueName')}
							/>
							<Form.Input
								label="Address"
								placeholder="Venue Address"
								value={venueAddress}
								onChange={this.handleFieldChange.bind(this, 'venueAddress')}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								label="City"
								placeholder="Venue City"
								value={venueCity}
								onChange={this.handleFieldChange.bind(this, 'venueCity')}
							/>
							<Form.Dropdown
								label="State"
								options={states}
								placeholder="Venue State"
								search
								selection
								value={venueState}
								onChange={this.handleFieldChange.bind(this, 'venueState')}
							/>
							<Form.Input
								label="Zip Code"
								placeholder="Venue Zip Code"
								value={venueZipCode}
								onChange={this.handleFieldChange.bind(this, 'venueZip')}
							/>
						</Form.Group>
					</Form>
				</Segment>

				<div className="center">
					<Button onClick={this.handleSubmit} color="pink" inverted>
						Upload Event
					</Button>
				</div>
			</div>
		);
	}
}

function buildDateTimeString(dt) {
	function pad(n) {
		return n < 10 ? '0' + n.toString() : n.toString();
	}

	if (dt == null) {
		return;
	} else {
		return (
			dt.getFullYear() +
			'-' +
			pad(dt.getMonth() + 1) +
			'-' +
			pad(dt.getDate()) +
			' ' +
			pad(dt.getHours()) +
			':' +
			pad(dt.getMinutes()) +
			':00'
		);
	}
}
