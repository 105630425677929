import React, { Component } from 'react';
import { Card, Image, Label, Icon } from 'semantic-ui-react';
import './eventCardStyles.css';

export default class EventCard extends Component {
	render() {
		let {
			title,
			description,
			url,
			urlLogo,
			start_time,
			end_time,
			tags,
			venue_name,
			venue_address,
			venue_city,
			venue_state,
			venue_zip,
		} = this.props;

		let tagsLabels = [];
		let tagList = [];

		if (tags != null && tags.length) {
			tags = tags.split(',');

			tags.map((tag, index) => {
					tagsLabels.push(<Label key={index} color='pink'>{tag}</Label>);
			})

			tagList = (
				<Label><Icon name='tags'/>Tags: </Label>
			);
		}

		let venueName = venue_name != null && venue_name.length ? <div><p>{venue_name}</p><br></br></div> : null;
		let venueAddress = venue_address != null && venue_address.length ? <div><p>{venue_address}</p><br></br></div> : null;
		let venueCity = venue_city != null && venue_city.length ? <p>{venue_city} </p> : null;
		let venueState = venue_state != null && venue_state.length ? <p>{venue_state} </p> : null;
		let venueZip = venue_zip != null && venue_zip.length ? <p>{venue_zip}</p> : null;

		let venueDateInfo = (
			<div>
				{venueName}
				{venueAddress}
				{venueCity}
				{venueState}
				{venueZip}
				<br></br>
				<p>{start_time} {start_time != null && start_time.trim().length && end_time != null && end_time.trim().length ? 'to' : ''} {end_time}</p>
			</div>
		);

		let extra = (
			<div>
				{tagList}
				{tagsLabels}
			</div>
		)

		return (
			<Card
				image={urlLogo}
				header={title}
				meta={url != null && url.length ? <a href={url}>Link</a> : null}
				description={venueDateInfo}
				extra={extra}
			/>
		);
	}
}
