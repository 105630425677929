import React, { Component } from 'react';
import {
	Form,
	Container,
	Segment,
	Label,
	Input,
	Button,
	Dropdown,
	Image,
  Visibility,
	Grid,
	Checkbox,
	Modal,
	Icon,
	Divider,
	TextArea
} from 'semantic-ui-react';
import _ from 'lodash';
import DateTimePicker from 'react-datetime-picker';
import FileDropTarget from '../FileDropTarget';
import RRuleGenerator from 'react-rrule-generator';
import RRule from 'rrule';
import logo from '../../assets/logo.svg';
import states from '../../assets/states.js';
import './eventManageStyles.css';
import '../../../node_modules/react-rrule-generator/build/styles.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import API from '../../services/API';
import moment from 'moment';
import VisibilitySensor from 'react-visibility-sensor';
import toastr from 'toastr';
import '../../../node_modules/toastr/build/toastr.css';
import iconLogo from '../../assets/Icon-Blue.jpg';
import roundTo from 'round-to';

export default class EventManage extends Component {
	state = {
		loading: true,
		events: [],
		tags: [],
		venues: [],
	};

	async componentDidMount() {
		try {

			const clientResponse = await API.get('/clients')

			const client = clientResponse.data.content;

			if (client.eventbrite_import_on && client.eventbrite_import_on === 1) {
				function guid() {
					function s4() {
						return Math.floor((1 + Math.random()) * 0x10000)
							.toString(16)
							.substring(1);
					}
					return (
						s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
					);
				}

				const chivvyEventsResponse = await API.get('/events/chivvy', {
					params: {
						source: 'Eventbrite',
					}
				});

				let clientEventbriteEvents = [];

				chivvyEventsResponse.data.content.map(event => {
					if (event.eventbrite_org_id === client.eventbrite_org_id) {
						clientEventbriteEvents.push(event);
					}
				})

				if (clientEventbriteEvents.length) {
					clientEventbriteEvents.map(event => {
						event.import_id = event.source_event_id;
						event.source_event_id = guid();
						event.source = 'producer-upload';
						event.status = 'producer-uploaded';
						event.owner_client_id = this.state.id;
					})

					const transferEventbriteEventsResponse = await API.post('/events', {
						events: clientEventbriteEvents,
					})
				}
			}

			const eventsResponse = await API.get('/events');

			const venuesResponse = await API.get('/venues');

			const tagsResponse = await API.get('/tags');

			let statusCounts = {
				'published': 0,
				'unpublished': 0
			};

			eventsResponse.data.content.forEach(event => {

				if (event.status === 'producer-published') {
					statusCounts.published ++;
				} else {
					statusCounts.unpublished ++;
				}

				let eventTags = [];
				if (event.tags != null && event.tags != "") {
					eventTags = event.tags.split('|');
				}
				event.tags = eventTags;

				event.startUnix = new Date(event.start_date).getTime();

				event.start_date = formatDate(event.start_date);
				event.end_date = formatDate(event.end_date);

				event.checked = 0;

				if (event.sticky == null) {
					event.sticky = 0;
				}

				if (event.featured == null) {
					event.featured = 0;
				}
			})

			tagsResponse.data.content.map(tag => {
				return tag;
			})

			this.setState({
				loading: false,
				events: eventsResponse.data.content,
				tags: tagsResponse.data.content,
				venues: venuesResponse.data.content,
				statusCounts: statusCounts
			});

		} catch(error) {
			console.log(error);
		}
	}

  render() {
    return (
			this.state.loading ?
				<Image src={iconLogo} size='tiny' className='App-logo'/>
			:
	      <EventList
	        events={this.state.events}
	        tags={this.state.tags}
					bearerToken={this.state.bearerToken}
					id={this.state.id}
					venues={this.state.venues}
					statusCounts={this.state.statusCounts}
	      />
    );
  }
}

class EventList extends Component {
  state = {
      availableTags: this.props.tags,
      filter: 'all',
      events: this.props.events,
      filteredEvents: this.props.events,
			saveNeeded: false,
			bearerToken: this.props.bearerToken,
			showPublished: false,
			statusCounts: this.props.statusCounts,
			allOption: 'select'
  };

  componentWillMount = () => {
      // mainContainer.addEventListener(that.tagEvent, e => this.updateTags(e.detail));
  }

  componentDidUpdate = () => {
      //window.scrollTo(0, 0);
  }

  // toggleEventSelected = (selectedEvent, e, data) => {
	//
	// 		console.log(selectedEvent);
	//
	// 		let events = _.cloneDeep(this.state.events);
	//
	// 		console.log(events);
	//
	// 		events.map(event => {
	// 			if (event.id === selectedEvent.id) {
	// 				event.checked = !event.checked;
	// 			}
	// 		});
	//
	// 		console.log(events);
	//
  //     this.setState({
  //         events: events
  //     });
  // }

	liftEventUp = (liftedEvent) => {
		console.log('lifted',liftedEvent);

		let events = [...this.state.events]

		events = events.map(event => {
			if (event.id === liftedEvent.id) {
				return liftedEvent;
			} else {
				return event;
			}
		});
		this.setState({
			events: events
		}, this.calculateStatusCounts);
	}

	selectDeselectAll = (which) => {
		let events = _.cloneDeep(this.state.events);

		let allOption = which === 'select' ? 'deselect' : 'select';

		events.map(event => {
			event.checked = which === 'select' ? 1 : 0;
		});

		this.setState({
			 events: events,
			 allOption: allOption
		});
	}

  handleSelectedStatusChange = (changedStatus) => {
    let events = this.state.events;

    events = events.map(event => {
		console.log('event.checked', event.checked, changedStatus)
		if (event.checked === 1 || event.checked == true) {
            event.status = changedStatus;
        }

		event.checked = 0;

        return event;
    });

		console.log(events);

    this.setState({
        events: events
    }, this.saveNeeded(), this.calculateStatusCounts);
  }

	save = () => {
		let events = [...this.state.events]
		let keepEvents = [];
		let deleteEventsIds = [];
		events.map(event => {
			let venueInfo = {};

			if (event.status === 'delete-pending') {
				deleteEventsIds.push(event.id);
			} else {
					if (event.venue_id && event.venue_id != null && event.venue_id != '') {
						venueInfo['venue_id'] = event.venue_id;
					} else {
						venueInfo = {
							"name": `${event.name}`,
							"address": `${event.address}`,
							"city": `${event.city}`,
							"state": `${event.state}`,
							"zip": `${event.zip}`,
							"lat": null,
							"lon": null
						}
					}

				event['venue'] = venueInfo;

				delete event.name;
				delete event.address;
				delete event.city;
				delete event.state;
				delete event.zip;
				delete event.venue_id;
				delete event.id;
				delete event.startUnix;
				delete event.date_created;
				delete event.date_modified;
				delete event.owner_client_id;
				delete event.checked;

				event.exported == null ? event.exported = 0 : event.exported = event.exported;
				event.featured == null ? event.featured = 0 : event.featured = event.featured;
				event.sticky == null ? event.sticky = 0 : event.sticky = event.sticky;

				keepEvents.push(event);
			}
		})

		if (!this.state.saveNeeded) {
			const toastrConfig = {
			  "preventDuplicates": false,
			  "timeOut": "5000",
			  "hideMethod": "fadeOut",
			  "progressBar": false,
			  "extendedTimeOut": "1000",
			  "showEasing": "swing",
			  "closeButton": true,
			  "hideEasing": "linear",
			  "positionClass": "toast-top-right",
			  "newestOnTop": false,
			  "showDuration": "300",
			  "debug": false,
			  "hideDuration": "1000",
			  "showMethod": "fadeIn"
			};

			toastr.options = toastrConfig;
			toastr.warning('No changes to save ...');
		} else {
			this.putEvents(keepEvents);
			this.deleteEvents(deleteEventsIds);
		}
	};

	putEvents = async (events) => {

		try {
			if (events.length) {
				const eventsResponse = await API.post('/events', {
					events: events
				});

				if (eventsResponse.data.success) {
					const toastrConfig = {
					  "preventDuplicates": false,
					  "timeOut": "5000",
					  "hideMethod": "fadeOut",
					  "progressBar": false,
					  "extendedTimeOut": "1000",
					  "showEasing": "swing",
					  "closeButton": true,
					  "hideEasing": "linear",
					  "positionClass": "toast-top-right",
					  "newestOnTop": false,
					  "showDuration": "300",
					  "debug": false,
					  "hideDuration": "1000",
					  "showMethod": "fadeIn"
					};

					toastr.options = toastrConfig;
					toastr.success('Events saved sucessfully ...', 'Woohoo!');

					this.saveNotNeeded();
				} else {
					toastr.error(`${eventsResponse.data.message}`,'Darn, events not saved ...');
				}
			}
		} catch(error) {
			console.log(error);

			toastr.error(`${error}`, 'Whoops, events not saved ...');
		}
	};

	deleteEvents = async (deleteIds) => {
		try {
			if (deleteIds.length) {
				console.log(deleteIds)
				const deleteResponse = await API.delete('/events', {
					data: {
						eventIds: deleteIds
					}
				});

				if (deleteResponse.data.success) {
					this.saveNotNeeded();

					const toastrConfig = {
					  "preventDuplicates": false,
					  "timeOut": "5000",
					  "hideMethod": "fadeOut",
					  "progressBar": false,
					  "extendedTimeOut": "1000",
					  "showEasing": "swing",
					  "closeButton": true,
					  "hideEasing": "linear",
					  "positionClass": "toast-top-right",
					  "newestOnTop": false,
					  "showDuration": "300",
					  "debug": false,
					  "hideDuration": "1000",
					  "showMethod": "fadeIn"
					};

					toastr.options = toastrConfig;
					toastr.success('Events deleted sucessfully ...', 'Alrighty,');

					let events = _.cloneDeep(this.state.events);

					deleteIds.map(deletedId => {
						events = events.filter(event => {
							return event.id !== deletedId;
						})
					})
					this.setState({
						events: events
					});

				} else {
					toastr.error(`${deleteResponse.data.message}`, 'Darn, events not deleted ...');
				}
			}
		} catch(error) {
			console.log(error);
			toastr.error(`${error}`, 'Darn, events not deleted ...');
		}
	};

	addRecurrences = (newEvents) => {
		let events = _.cloneDeep(this.state.events);

		let combined = events.concat(newEvents);

		this.setState({
			events: combined
		}, this.saveNeeded());
	}

	editVenue = (newEvent) => {
		let events = _.cloneDeep(this.state.events);

		events.map(event => {
			if (event.id === newEvent.id) {
				event.name = newEvent.name;
				event.address = newEvent.address;
				event.city = newEvent.city;
				event.state = newEvent.state;
				event.zip = newEvent.zip;
				event.venue_id = newEvent.venue_id;
			}
		})

		this.setState({
			events: events
		});

		this.saveNeeded();
	}

	saveNotNeeded = () => {
		this.setState({
			saveNeeded: false
		});
	}

	saveNeeded = () => {
		this.setState({
			saveNeeded: true
		});
	}

	changeStatusFilter = (showPublished) => {
		this.setState({
			showPublished: showPublished
		}, this.calculateStatusCounts());
	}

	calculateStatusCounts = () => {
		let statusCounts = {
			'published': 0,
			'unpublished': 0
		};

		this.state.events.map(event => {
			if (event.status === 'producer-published') {
				statusCounts.published ++;
			} else {
				statusCounts.unpublished ++;
			}
		})

		this.setState({
			statusCounts: statusCounts
		});
	}

  render() {
			const state = this.state;
      const {contextRef} = this.state;

			let sorted = [...state.events].sort((a,b) => a.startUnix - b.startUnix);

			let statusFiltered = sorted.filter(filteredEvent => {
				if (state.showPublished) {
					return filteredEvent.status === 'producer-published';
				} else if (!state.showPublished) {
					return filteredEvent.status === 'producer-uploaded';
				}
			});
      let eventDivs = statusFiltered.map((event, index) => {
          return (
              <EventItem
				className='eventItem'
                key={event.id}
                event={event}
                availableTags={this.props.tags}
                selected={event.checked}
								liftEventUp={this.liftEventUp}
								venues={this.props.venues}
								addRecurrences={this.addRecurrences}
								saveNeeded={this.saveNeeded}
								editVenue={this.editVenue}
              />
          );
      });

      let list = (
					<Segment.Group>
						{eventDivs}
					</Segment.Group>
      );

			let noEventsAlert = (
				<Button fluid disabled color='orange'>Whoops ... There are no events to Manage. Go to the Add page and import events to see them here.</Button>
			)

			let allButton = this.state.allOption === 'select' ? (
				<Button onClick={() => this.selectDeselectAll('select')}>
					Select All
				</Button>
			) : (
				<Button onClick={() => this.selectDeselectAll('deselect')}>
					Deselect All
				</Button>
			)

      return (
        <div className='EventList' id='event-list'>
            <Grid className='EventList'>
							<Grid.Row className='sticky buttonsRow'>
								<Grid.Column>
									<Grid.Row className='saveButtonRow' columns={3}>
										<Grid.Column width={5}>
											<div></div>
										</Grid.Column>
										<Grid.Column width={6} className='statusFilterColumn'>
											<Button.Group>
												<Button active={!this.state.showPublished} onClick={() => this.changeStatusFilter(false)}>
													Unpublished ({this.state.statusCounts.unpublished})
												</Button>
												<Button.Or />
												<Button active={this.state.showPublished} onClick={() => this.changeStatusFilter(true)}>
													Published ({this.state.statusCounts.published})
												</Button>
											</Button.Group>
										</Grid.Column>
										<Grid.Column floated='right' className='saveButtonColumn' width={5}>
											{this.state.saveNeeded ? <Label color='yellow' pointing='right'>Make Sure to Save Your Changes!</Label> : ''}
											<Button id='saveButton' color='pink' inverted icon labelPosition='left' onClick={this.save}>
												<Icon name='save' />
												Save
											</Button>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column floated='left' width={8} className='button-col-left'>
											{allButton}
										</Grid.Column>
										<Grid.Column floated='right' width={8} className='button-col-right'>
											<Button color='green' inverted onClick={() => this.handleSelectedStatusChange('producer-published')}>
												Publish Selected
											</Button>
											<Button color='orange' inverted onClick={() => this.handleSelectedStatusChange('producer-uploaded')}>
												Unpublish Selected
											</Button>
											<Button color='red' inverted onClick={() => this.handleSelectedStatusChange('delete-pending')}>
												Delete Selected
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row id='event-list-container'>
								{this.state.events.length? list : noEventsAlert}
							</Grid.Row>
            </Grid>
        </div>
      );
  }
}

class EventItem extends Component {
  state = {
    event: this.props.event,
    tags: this.props.event.tags || [],
    selected: []
  };

  componentWillReceiveProps = (nextProps) => {
      if (!_.isEqual(nextProps.event, this.state.event)) {
          this.setState({
              event: nextProps.event,
              tags: nextProps.event.tags || []
          });
      }
  }

  removeTag = (tag) => {
      const event = this.state.event;
      const tags = this.state.tags;

      let index = tags.indexOf(tag);

      if (index > -1) {
          tags.splice(index, 1);
          event.tags = tags;
          this.setState({
              event: event
          }, this.props.saveNeeded);
      }
  }

  addTag = (tag) => {
      const event = this.state.event;
      const tags = this.state.tags;
      let index = tags.indexOf(tag);

      if (index <= -1 && tag) {
          tags.push(tag);
          event.tags = tags;
          this.setState({
              event: event
          }, this.props.saveNeeded);
      }
  }

	handleStatusChange = (changedStatus) => {
		console.log('changed status', changedStatus)
		let event = _.cloneDeep(this.state.event);

		event.status = changedStatus;
		
		this.setState({
			event: event
		}, this.props.liftEventUp(event));

		this.props.saveNeeded();
	}

  editEvent = (property, value) => {
      const event = this.state.event;
      event[property] = value;

      this.setState({
          event: event
      }, this.props.saveNeeded());
  }

  editEventImage = () => {
      // api.output('editEventImage', this.state.event);
  }

	handleToggleChange = (property) => {
		const event = _.cloneDeep(this.state.event);

		console.log(event);

		if (event[property] === 1) {
			event[property] = 0;
		} else {
			event[property] = 1;
		}

		console.log(event);

		this.setState({
			event: event
		}, this.props.liftEventUp(event));

		if (property !== 'checked') {
			this.props.saveNeeded();
		}
	}

  render() {
      let event = this.state.event;
      let tags = this.state.event.tags;

      let selected = this.props.selected;
      let labelColor;

      switch (event.status) {
          case 'rejected':
          case 'admin-rejected':
          case 'auto-rejected':
          case 'delete-pending':
              labelColor = 'red';
              break;
          case 'approved':
          case 'admin-approved':
          case 'auto-approved':
          case 'producer-published':
              labelColor = 'green';
              break;
          default:
              labelColor = 'orange';
      }

			let sticky = (
				<div className='stickyToggle'>
					<Label color={event.sticky === 1 ? 'green' : 'orange'}>Sticky: </Label>
					<Checkbox toggle name='sticky' checked={event.sticky === 1} onChange={() => this.handleToggleChange('sticky')} />
				</div>
			)

			let featured = (
				<div className='featuredToggle'>
					<Label color={event.featured === 1 ? 'green' : 'orange'}>Featured: </Label>
					<Checkbox toggle checked={event.featured === 1} onChange={() => this.handleToggleChange('featured')} />
				</div>
			)
      return (
          <Segment className='EventItem'>
						<Grid divided='vertically'>
							<Grid.Row columns={2} divided>
								<Grid.Column className='eventItemLeftColumn' width={11}>
									<div className='event-info'>
										<Title title={event.title} url={event.url} urlLogo={event.urlLogo} editTitle={this.editEvent} />
										<Venue event={this.state.event} name={event.name} editVenue={this.props.editVenue} venues={this.props.venues}/>
										<DateInfo start={event.start_date} end={event.end_date} editDate={this.editEvent} addRecurrences={this.props.addRecurrences} event={event} />
										<TagList tags={tags} removeTag={this.removeTag} addTag={this.addTag} availableTags={this.props.availableTags} />
										{sticky}
										{featured}
									</div>
								</Grid.Column>
								<Grid.Column className='eventItemRightColumn' floated='right' width={5}>
									<Grid.Row>
										<Grid.Column floated='right'>
											<div className='event-checkbox'>
												<Button color='green' inverted onClick={() => this.handleStatusChange('producer-published')}>
													Publish
												</Button>
												<Button color='orange' inverted onClick={() => this.handleStatusChange('producer-uploaded')}>
													Unpublish
												</Button>
												<Button color='red' inverted onClick={() => this.handleStatusChange('delete-pending')}>
													Delete
												</Button>
												<Checkbox checked={event.checked === 1} onChange={() => this.handleToggleChange('checked')} />
											</div>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column floated='right'>
											<div className='statusContainer'>
												<Label size='medium' color={labelColor}>
													{event.status === 'unapproved' ? 'PENDING REVIEW' : event.status.toUpperCase()}
												</Label>
											</div>
										</Grid.Column>
									</Grid.Row>
									{event.urlLogo && (
									<Grid.Row>
										<Grid.Column floated='right'>
											<div className='event-img'>
												<Image src={`https://${event.urlLogo}`} />
											</div>
										</Grid.Column>
									</Grid.Row>
									)}
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<div className='descriptionContainer'>
									<Description text={event.description} editDescription={this.editEvent} />
								</div>
							</Grid.Row>
						</Grid>
          </Segment>
      );
  }
}

function Title(props) {
	return (
		<div>
			<EditableField long={false} value={props.title} name='Title' tag='h1' onFieldEdit={value => props.editTitle('title', value)} />
			<div className='event-url-container'>
				<Label className='itemLabel'>Link: </Label>
				<EditableField long={false} value={props.url} name='Url' tag='p' onFieldEdit={value => props.editTitle('url', value)} />
			</div>
			<div className='image-url-container'>
				<Label className='itemLabel'>Image URL: </Label>
				<EditableField long={false} value={props.urlLogo} tag='p' onFieldEdit={value => props.editTitle('urlLogo', value)} />
			</div>
		</div>
	);
}

class Venue extends Component {
	state = {
		venues: this.props.venues,
		event: this.props.event,
		modalOpen: false,
		venueId: null,
		name: '',
		address: '',
		city: '',
		state: '',
		zip: '',
	};

	handleFieldChange = (property, e, { value }) => {
		this.setState({
			[property]: value,
			venueId: null
		});
	};

	handlePresetVenueChange = (property, e, { value }) => {
		this.setState({
			[property]: value,
		});
	};

	modalSwitch = () => {
		this.setState({
			modalOpen: !this.state.modalOpen
		});
	}

	submitVenue = () => {
		let event = _.cloneDeep(this.state.event);
		let venues = _.cloneDeep(this.state.venues);

		if (this.state.venueId) {
			event.venue_id = this.state.venueId;

			let thisVenue = _.find(venues, {'id':this.state.venueId})

			event.name = thisVenue.name;
			event.address = thisVenue.address;
			event.city = thisVenue.city;
			event.state = thisVenue.state;
			event.zip = thisVenue.zip;
			event.venue_id = this.state.venueId;
		} else {
			event.name = this.state.name;
			event.address = this.state.address;
			event.city = this.state.city;
			event.state = this.state.state;
			event.zip = this.state.zip;
			event.venue_id = this.state.venueId;
		}

		this.setState({
			event: event,
		}, this.modalSwitch);

		this.props.editVenue(event);
	}

	render() {
			const {
				name,
				address,
				city,
				state,
				zip
			} = this.state;

			let venueOpts = [];
			this.state.venues.map(venue => {
				if (venue.name) {
					venueOpts.push({
						key: `${venue.id}`,
						value: venue.id,
						text: `${venue.name}`
					});
				}
			});

			return (
				<div className='venueContainer'>
					<Label className='itemLabel'>Venue: </Label>
					<p>{this.props.name}</p>
					<Modal open={this.state.modalOpen} onClose={this.modalSwitch} trigger={<Button icon size='tiny' onClick={this.modalSwitch}><Icon name='edit' />Edit</Button>}>
				    <Modal.Header>Edit Venue</Modal.Header>
				    <Modal.Content>
							<Label color='pink' ribbon>Select a Preexisting Venue</Label>
							<Dropdown
								fluid
								search
								selection
								scrolling
								placeholder='Search/Select a Preexisting Venue'
								options={venueOpts}
								onChange={this.handlePresetVenueChange.bind(this, 'venueId')}
							/>
							<Divider horizontal>Or</Divider>
							<Label color="pink" ribbon>
								If Venue not Found, Create it Below
							</Label>
							<Form>
								<Form.Group widths="equal">
									<Form.Input
										label="Name"
										placeholder="Venue Name"
										value={name}
										onChange={this.handleFieldChange.bind(this, 'name')}
									/>
									<Form.Input
										label="Address"
										placeholder="Venue Address"
										value={address}
										onChange={this.handleFieldChange.bind(this, 'address')}
									/>
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Input
										label="City"
										placeholder="Venue City"
										value={city}
										onChange={this.handleFieldChange.bind(this, 'city')}
									/>
									<Form.Dropdown
										label="State"
										options={states}
										placeholder="Venue State"
										search
										selection
										value={state}
										onChange={this.handleFieldChange.bind(this, 'state')}
									/>
									<Form.Input
										label="Zip Code"
										placeholder="Venue Zip Code"
										value={zip}
										onChange={this.handleFieldChange.bind(this, 'zip')}
									/>
								</Form.Group>
							</Form>
							<Button color='green' fluid inverted onClick={this.submitVenue}>Change Venue</Button>
				    </Modal.Content>
				  </Modal>
				</div>
			)
	}
}

class DateInfo extends Component {
	state = {
		start: this.props.start,
		end: this.props.end,
		rrule: 'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1',
		showModal: false,
		event: this.props.event,
		startDate: this.props.start
	};

	onChangeStart = (date) => {
		this.setState({
			start: date
		});

		this.props.editDate('start_date', buildDateTimeString(date));

	}

	onChangeEnd = (date) => {
		this.setState({
			end: date
		});

		this.props.editDate('end_date', buildDateTimeString(date));

	}

	handleRRuleChange = (rrule) => {
		this.setState({
			rrule: rrule
		});
	}

	generateRecurrences = () => {
		let event = _.cloneDeep(this.state.event);

		let newEvents = [];

		let rrule = this.state.rrule;

		let startOptions = RRule.parseString(rrule);
		startOptions.dtstart = new Date(this.state.start);
		let startRule = new RRule(startOptions);

		let startRecurrences = {'recurrences': startRule.all()};
		startRecurrences['originalDate'] = this.state.start;

		let endOptions = RRule.parseString(rrule);
		endOptions.dtstart = new Date(this.state.end);
		let endRule = new RRule(endOptions);

		let endRecurrences = {'recurrences': endRule.all()};
		endRecurrences['originalDate'] = this.state.end;

		let newEventDates = [];

		for (var i = 1; i < startRecurrences.recurrences.length; i++) {
    	newEventDates.push({
				"start": startRecurrences.recurrences[i],
				"end": endRecurrences.recurrences[i],
			});
		}

		function guid() {
	    function s4() {
	      return Math.floor((1 + Math.random()) * 0x10000)
	        .toString(16)
	        .substring(1);
	    }
	    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	  }

		newEventDates.map(date => {
			let eventModel = _.cloneDeep(event);

			let newValues = {};

			newValues.startUnix = new Date(date.start).getTime();
			newValues.start_date = buildDateTimeString(date.start);
			newValues.end_date = buildDateTimeString(date.end);
			newValues.source_event_id = guid();
			newValues.id = null;

			let newEvent = Object.assign(eventModel, newValues);

			newEvents.push(newEvent);
		})

		this.props.addRecurrences(newEvents);

		this.closeModal();
	}

	closeModal = () => {
		this.setState({
			showModal: false
		});
	}

	openModal = () => {
		this.setState({
			showModal: true
		});
	}

	render() {
		let rruleConfig = {
			end: ['After', 'On date'],
			hideError: true
		};

		let recurrenceButton = (
			<Button icon labelPosition='left' onClick={this.openModal} className='ui labeled icon button' inline='true' color='blue' size='tiny'>
				<Icon name='repeat' />
				Recur
			</Button>
		)

		let recurrenceGenerator = (
			<Modal id='recurrenceModal' open={this.state.showModal} onClose={this.closeModal} trigger={recurrenceButton}>
				<Modal.Header as='h2'>
					<Image src={this.state.event.urlLogo} avatar />
					<span>{this.state.event.title}</span>
				</Modal.Header>
				<Modal.Content>
					<RRuleGenerator onChange={this.handleRRuleChange} config={rruleConfig} />
					<Button floated='right' id='createRecurrencesButton' icon labelPosition='left' onClick={this.generateRecurrences} className='ui labeled icon button' color='blue'>
						<Icon name='repeat' />
							Create Recurrences
					</Button>
				</Modal.Content>
			</Modal>
		)

		let datePanel = (
			<div className='datetimeContainer'>
				<DateTimePicker value={this.state.start == null || this.state.start === '' ? null : new Date(this.state.start)} onChange={this.onChangeStart} className='startDateTime' calendarClassName='calendar' disableClock/>
				<DateTimePicker value={this.state.start == null || this.state.end === '' ? null : new Date(this.state.end)} onChange={this.onChangeEnd} className='endDateTime' calendarClassName='calendar' disableClock/>
				{recurrenceGenerator}
			</div>
		)

		return datePanel;
	}
}

class TagList extends Component {
	state = {
		addingTag: false
	};

	componentDidUpdate = () => {
		//in theory this is where we maybe focus on the input? Not sure.
		// Have to use "open" instead of "focus" since this is actually a third-party component from Semantic UI. :\
		if (this.textInput) {
			this.textInput.open();
		}
	}

	removeTag = (tag) => {
			this.props.removeTag(tag);
	}

	addTag = (e, data) => {
			let tag = data.value;

			this.setState({
					addingTag: false
			});

			if (tag !== 'select-tag')
				this.props.addTag(tag);
	}

	showSelectList = () => {
			this.setState({
					addingTag: true
			});
	}

	handleKeyDown = (event) => {
			if (event.keyCode === 27) {
					this.setState({
							addingTag: false
					});
			}
	}

	handleRef = (input) => {
		this.textInput = input;
	}

	render() {
			let tags = this.props.tags || [];

			tags = tags.sort();

			let tagDivs = tags.map((tag, index) => {
					return (
							<Tag key={index} text={tag} onTagClick={this.removeTag} />
					);
			});

			let addTagButton;

			if (!this.state.addingTag) {
				addTagButton = <Button color='green' inverted size='tiny' icon onClick={this.showSelectList}><Icon name='plus' />Add</Button>;
			}

			let tagOpts = [];
			this.props.availableTags.map((tag, index) => {
				tagOpts.push(
					{key:index+1, value:tag.tag, text:tag.tag}
				);
			})

			let tagSelectList;

			if (this.state.addingTag) {
				tagSelectList = <Dropdown selectOnBlur={false} placeholder='Select Tag' onChange={this.addTag} onAddItem={this.addTag} search={true} selection={true} allowAdditions={true} additionpostion='bottom' options={tagOpts} onKeyDown={this.handleKeyDown} ref={this.handleRef}/>;
			}

			let tagsLabel = (
				<Label>Tags:</Label>
			);

			return (
					<div className='Taglist'>
						{tagsLabel}
						{tagDivs}
						{addTagButton}
						{tagSelectList}
					</div>
			);
	}
}

class Tag extends Component {
	handleClick = (e) => {
			this.props.onTagClick(this.props.text);
	}

	render() {
			return (
				<Label as='a' color='pink' onClick={this.handleClick}>
					{this.props.text}
					<Icon name='delete' />
				</Label>
			);
	}
}

class Description extends Component {
	setDescription = (value) => {
			this.props.editDescription('description', value);
	}

	render() {
			return (
				<EditableField long={true} value={this.props.text} name='Description' tag='p' onFieldEdit={this.setDescription} />
			);
	}
}

class EditableField extends Component {
	state = {
		editing: false,
		value: this.props.value,
		originalValue: this.props.value
	};

	componentWillReceiveProps = (nextProps) => {
			this.setState({
					editing: false,
					value: nextProps.value,
					originalValue: nextProps.value
			});
	}

	componentDidUpdate = (prevProps, prevState) => {
			let ref = this[this.props.name];
			if (ref) {
					ref.focus();
			}
	}

	editField = () => {
			this.setState({
					editing: true
			});
	}

	handleChange = (event) => {
			this.setState({
					value: event.target.value
			});
	}

	handleSubmit = (event) => {
			event.preventDefault();

			let value = this.state.value.trim();

			if (value) {
					this.setState({
							editing: false,
							value: value,
							originalValue: value
					});

					this.props.onFieldEdit(value);
			}
	}

	handleBlur = (event) => {
		setTimeout(() => {
			this.setState({
				editing: false,
			});
		}, 100);
	}

	handleCancel = (event) => {
			event.preventDefault();

			this.setState({
					editing: false,
					value: this.state.originalValue
			});
	}

	handleKeyPress = (event) => {
			if (event.key === 'Enter' && event.nativeEvent.shiftKey) {
					if (event.target.value.trim()) {
							this.handleSubmit(event);
					}
			}
	}

	handleKeyDown = (event) => {
			if (event.keyCode === 27) {
				this.handleCancel(event);
			}
	}

	getHighlightedText = (text, highlight) => {

			if (highlight) {
				let parts = text.split(new RegExp(`(${ highlight })`, 'gi'));

				return React.createElement('span', {}, parts.map((part, i) => {
						return React.createElement('span', {key: i, className: part.toLowerCase() === highlight.toLowerCase() ? 'text-highlight' : ''}, part);
				}));
			} else {
				return text;
			}
	}

	render() {
			let DynInput = this.props.long ? 'textarea' : 'input';
			let help = <p>Shift + Enter to Submit</p>;
			let Element = this.props.tag;

			let value = '';

			let input = (
				<DynInput className={DynInput === 'textarea' ? 'edit-textarea' : 'edit-input'} value={this.state.value} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onKeyDown={this.handleKeyDown} onBlur={this.handleBlur} ref={(input) => this[this.props.name] = input} />
			);

			let field;

			if (this.state.editing) {
				field = (
					<form className='edit-form' onSubmit={this.handleSubmit}>
						{input}
						{DynInput === 'textarea' ? help : null}
						<Button color='red' inverted size='tiny' icon onClick={this.handleCancel}><Icon name='cancel' />Cancel</Button>
					</form>
				);
			} else {
				if (this.state.value) {
					field = (
						<Element onClick={this.editField} className={this.props.name}>
							{this.getHighlightedText(this.state.value, this.props.searchTerm)}
						</Element>
					);
				} else {
					field = (
						<button onClick={this.editField} className={`${this.props.name} missing-data-btn`}>
							Missing {this.props.name}
						</button>
					);
				}
			}

			return field;
	}
}

function formatDate(eventDate) {
	let newDate;

	if (eventDate && eventDate != null && eventDate != ''){
		if (eventDate.indexOf("Z") > -1) {
			newDate = eventDate.substr(0,19);
			newDate = newDate.replace("T", ' ');

			return newDate;
		} else {
			return eventDate;
		}
	} else {
		return null
	}

}

function buildDateTimeString(dt) {

  function pad(n){return n<10 ? '0'+n.toString() : n.toString();}

  return dt.getFullYear()+'-'+pad(dt.getMonth()+1)+'-'+pad(dt.getDate())+' '+pad(dt.getHours())+':'+pad(dt.getMinutes())+':00';
}
