export default {
	Title: {
		prop: 'title',
		type: String,
	},
	Description: {
		prop: 'description',
		type: String,
	},
	'Start Time': {
		prop: 'start_time',
		type: String,
	},
	'End Time': {
		prop: 'end_time',
		type: String,
	},
	'URL': {
		prop: 'url',
		type: String,
	},
	Image: {
		prop: 'url_logo',
		type: String,
	},
	'Venue Name': {
		prop: 'venue_name',
		type: String,
	},
	'Venue Address': {
		prop: 'venue_address',
		type: String,
	},
	'Venue City': {
		prop: 'venue_city',
		type: String,
	},
	'Venue State': {
		prop: 'venue_state',
		type: String,
	},
	'Venue Zip code': {
		prop: 'venue_zip',
		type: String,
	},
	Tags: {
		prop: 'tags',
		type: String,
	},
};
