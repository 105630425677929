import React, { Component, createRef } from 'react';
import API from '../../services/API';
import {
	Segment,
	Label,
	Button,
	Dropdown,
	Image,
  Visibility,
	Grid,
	Checkbox,
	Icon,
	Sidebar,
	Accordion,
} from 'semantic-ui-react';
import './promoterCurateStyles.css';
import _ from 'lodash';
import toastr from 'toastr';
import DateTimePicker from 'react-datetime-picker';
import VisibilitySensor from 'react-visibility-sensor';
import statuses from './statuses.js';
import iconLogo from '../../assets/Icon-Blue.jpg';

export default class PromoterCurate extends Component {
	state = {
		loading: true,
		events: [],
		tags: [],
		statuses: statuses,
		producers: [],
		tagsFilters: [],
		districts: [],
		venues: [],
	};

	async componentDidMount() {
		try {

			const eventsResponse = await API.get('/events/curate');

			const tagsResponse = await API.get('/tags')

			// let statuses = _.cloneDeep(this.state.statuses);

			eventsResponse.data.content.map(event => {

				let eventTags = [];
				if (event.tags != null && event.tags != "") {
					eventTags = event.tags.split(',');
				}
				event.tags = eventTags;

				let eventDistricts = [];
				if (event.districts != null && event.districts != "") {
					eventDistricts = event.districts.split(',');
				}
				event.districts = eventDistricts;

				event.startUnix = new Date(event.start_date).getTime();

				if (event.status === 'producer-published' || event.status === 'unapproved' || event.status === 'auto-approved' || event.status === 'admin-approved') {
			    statuses[1].count ++;
			  } else if (event.status === 'approved' || event.status === 'published') {
			    statuses[2].count ++;
			  } else {
			    statuses[3].count ++;
			  }

				if (event.sticky == null) {
					event.sticky = 0;
				}

				if (event.featured == null) {
					event.featured = 0;
				}

				event.start_date = formatDate(event.start_date);
				event.end_date = formatDate(event.end_date);
			})

			statuses[0].count = eventsResponse.data.content.length;

			tagsResponse.data.content.map(tag => {
				return tag;
			})

			this.setState({
				loading: false,
				events: eventsResponse.data.content,
				tags: tagsResponse.data.content
			});

		} catch(error) {
			console.log(error);
		}
	}

	handleStickyFeatureToggle = (editedEvent, field) => {
		let events = _.cloneDeep(this.state.events);

		events.map(event => {
			if (event.id === editedEvent.id) {
				if (event[field] === 1 ){
					event[field] = 0;
				} else {
					event[field] = 1;
				}
			}
		})

		this.setState({
			events: events
		})
	}

  render() {

    return (
			this.state.loading ?
				<Image src={iconLogo} size='tiny' className='App-logo'/>
			:
					<EventList
		        events={this.state.events}
		        tags={this.state.tags}
		        statuses={statuses}
						producers={this.state.producers}
						tagsFilters={this.state.tagsFilters}
						districts={this.state.districts}
						venues={this.state.venues}
						handleStickyFeatureToggle={this.handleStickyFeatureToggle}
						bearerToken={this.state.bearerToken}
						id={this.state.id}
		      />
    );
  }
}

class EventList extends Component {
  state = {
      availableTags: this.props.tags,
      filter: 'all',
      events: this.props.events,
			activeEvents: this.props.events,
      filteredEvents: this.props.events,
      page: 1,
      selected: [],
      statuses: this.props.statuses,
			sidebarHidden: true,
			accordionActiveIndex: 0,
			producers: [],
			tags: [],
			districts: [],
			venues: [],
			producerFilters: [],
			tagFilters: [],
			districtFilters: [],
			venueFilters: [],
			activeProducers: [],
			activeTags: [],
			activeDistricts: [],
			activeVenues: [],
			saveNeeded: false
  };

	contextRef = createRef()

  componentWillMount = () => {
  }

  componentDidUpdate = () => {
  }

	componentWillReceiveProps = (nextProps) => {
      this.setState({
        searchTerm: nextProps.searchTerm,
        events: nextProps.events,
				availableTags: nextProps.tags,
				statuses: nextProps.statuses,
				filteredEvents: nextProps.events,
				activeEvents: nextProps.events
      });
  }

  toggleEventSelected = (event, e, data) => {
      let selected = _.cloneDeep(this.state.selected);

      if (data.checked) {
          selected.push(event);
      } else {
          _.pullAllWith(selected, [event], _.isEqual);
      }

      this.setState({
          selected
      });
  }

  approveAll = () => {
    let selected = _.cloneDeep(this.state.filteredEvents);
    let events = _.cloneDeep(this.state.events);
		let activeEvents = _.cloneDeep(this.state.activeEvents);

    events = events.map(event => {
        if (_.findIndex(selected, e => e.id === event.id) > -1) {
            event.status = 'approved';
        }

        return event;
    });

		activeEvents = activeEvents.map(event => {
        if (_.findIndex(selected, e => e.id === event.id) > -1) {
            event.status = 'approved';
        }

        return event;
    });

    this.setState({
        events: events,
				activeEvents: activeEvents,
        selected: [],
				saveNeeded: true
    }, this.filterEvents);
  }

  approveSelected = () => {
    let selected = _.cloneDeep(this.state.selected);
    let events = _.cloneDeep(this.state.events);
		let activeEvents = _.cloneDeep(this.state.activeEvents);

    events = events.map(event => {
        if (_.findIndex(selected, e => e.id === event.id) > -1) {
            event.status = 'approved';
        }

        return event;
    });

		activeEvents = activeEvents.map(event => {
        if (_.findIndex(selected, e => e.id === event.id) > -1) {
            event.status = 'approved';
        }

        return event;
    });

    this.setState({
        events: events,
				activeEvents: activeEvents,
        selected: [],
				saveNeeded: true
    }, this.filterEvents);
  }

  rejectAll = () => {
    let selected = _.cloneDeep(this.state.filteredEvents);
    let events = _.cloneDeep(this.state.events);
		let activeEvents = _.cloneDeep(this.state.activeEvents);

    events = events.map(event => {
        if (_.findIndex(selected, e => e.id === event.id) > -1) {
            event.status = 'rejected';
        }
        return event;
    });

		activeEvents = activeEvents.map(event => {
        if (_.findIndex(selected, e => e.id === event.id) > -1) {
            event.status = 'rejected';
        }

        return event;
    });

    this.setState({
        events: events,
				activeEvents: activeEvents,
        selected: [],
				saveNeeded: true
    }, this.filterEvents);
  }

  rejectSelected = () => {
    let selected = _.cloneDeep(this.state.selected);
    let events = _.cloneDeep(this.state.events);
		let activeEvents = _.cloneDeep(this.state.activeEvents);

    events = events.map(event => {
        if (_.findIndex(selected, e => e.id === event.id) > -1) {
            event.status = 'rejected';
        }
        return event;
    });

		activeEvents = activeEvents.map(event => {
        if (_.findIndex(selected, e => e.id === event.id) > -1) {
            event.status = 'rejected';
        }

        return event;
    });

    this.setState({
        events: events,
				activeEvents: activeEvents,
        selected: [],
				saveNeeded: true
    }, this.filterEvents);
  }

  handleFilterChange = (value) => {
    let filter = value.target.innerHTML.substr(0,2);

    let filterValue = filter === 'Al' ? 'all' : filter === 'No' ? 'not reviewed' : filter === 'Re' ? 'rejected' : 'approved';

    this.setState({
        filter: filterValue,
        page: 1
    }, this.filterEvents);

    window.scrollTo(0, 0);
    // document.getElementById('results').scrollTop = 0;
  }

  filterEvents = () => {
      let unfilteredEvents = _.cloneDeep(this.state.events);
      let filteredEvents = unfilteredEvents.filter((event) => {
          let filter = this.state.filter;

          if (filter === 'all') {
              return true;
          } else if (filter === 'not reviewed'){
              return (event.status === 'unapproved' || event.status === 'producer-published' || event.status === 'auto-approved' || event.status === 'admin-approved');
          } else if (filter === 'approved') {
              return (event.status === 'approved' || event.status === 'published');
          } else {
              return event.status === 'rejected';
          }
      });

      this.setState({
          filteredEvents: filteredEvents
      }, this.updateStatuses);
  }

  scrollUpdate = (e, {calculations}) => {
      if (calculations.percentagePassed > 0.5) {
          this.setState({
              page: this.state.page + 1
          });
      }
  }

  handleContextRef = (contextRef) => {
    this.setState({contextRef});
  }

	handleSidebarVis = () => {
		let sidebarHidden = _.cloneDeep(this.state.sidebarHidden);

		this.setState({sidebarHidden: !sidebarHidden});
	}

	handleAccordion = (e, titleProps) => {
		const { index } = titleProps;
    const { accordionActiveIndex } = this.state;
    const newIndex = accordionActiveIndex === index ? -1 : index;

    this.setState({ accordionActiveIndex: newIndex });
	}

	handleProducerCheckFilterChange = (e) => {
		let value = e.target.innerHTML;
		let producerFilters = _.cloneDeep(this.state.producerFilters);

		let index = producerFilters.indexOf(value);

		index > -1 ? producerFilters.splice(index,1) : producerFilters.push(value);

		this.setState({producerFilters: producerFilters},this.updateActiveEvents);
	}

	handleTagCheckFilterChange = (e) => {
		let value = e.target.innerHTML;
		let tagFilters = _.cloneDeep(this.state.tagFilters);

		let index = tagFilters.indexOf(value);

		index > -1 ? tagFilters.splice(index,1) : tagFilters.push(value);

		this.setState({tagFilters: tagFilters},this.updateActiveEvents);
	}

	handleDistrictCheckFilterChange = (e) => {
		let value = e.target.innerHTML.toString();
		let districtFilters = _.cloneDeep(this.state.districtFilters);

		let index = districtFilters.indexOf(value);
		index > -1 ? districtFilters.splice(index,1) : districtFilters.push(value);

		this.setState({districtFilters: districtFilters},this.updateActiveEvents);
	}

	handleVenueCheckFilterChange = (e) => {
		let value = e.target.innerHTML;

		let venueFilters = _.cloneDeep(this.state.venueFilters);

		let index = venueFilters.indexOf(value);

		index > -1 ? venueFilters.splice(index,1) : venueFilters.push(value);

		this.setState({venueFilters: venueFilters},this.updateActiveEvents);
	}

	updateActiveEvents = () => {
		let events = _.cloneDeep(this.state.events);
		let activeEvents = events.filter(event => this.eventPassesFilters(event));
		let activeProducers = [];
		let activeTags = [];
		let activeDistricts = [];
		let activeVenues = [];

		activeEvents.map(event => {
			if (activeProducers.indexOf(event.producer) === -1) {
				activeProducers.push(event.producer);
			}

			if (event.tags.length) {
				event.tags.map(tag => {
					if (activeTags.indexOf(tag) === -1) {
						activeTags.push(tag);
					}
				})
			}

			if (event.districts.length) {
				event.districts.map(district => {
					if (activeDistricts.indexOf(district) === -1) {
						activeDistricts.push(district);
					}
				})
			}

			if (activeVenues.indexOf(event.name) === -1) {
				activeVenues.push(event.name);
			}
		})

		this.setState({
			activeEvents: activeEvents,
			activeProducers: activeProducers,
			activeTags: activeTags,
			activeDistricts: activeDistricts,
			activeVenues: activeVenues
		},this.updateStatuses);
	}

	eventPassesFilters = (event) => {
		let producers = this.state.producerFilters;
		let tags = this.state.tagFilters;
		let districts = this.state.districtFilters;
		let venues = this.state.venueFilters;

		let producer = event.producer;
		let eventTags = event.tags;
		let eventDistricts = event.districts;
		let venue = event.name;

		if (producers.length > 0 && !producers.includes(producer)) {
			return false;
		}

		if (venues.length > 0 && !venues.includes(venue)) {
			return false;
		}

		if (tags.length > 0 && !eventTags.some(tag => tags.includes(tag))) {
			return false;
		}

		if (districts.length > 0 && !eventDistricts.some(district => districts.includes(district))) {
			return false;
		}

		return true;
	}

	clearFilters = () => {
		this.setState({
			producerFilters: [],
			tagFilters: [],
			districtFilters: [],
			venueFilters: []
		}, this.updateActiveEvents);
	}

	handleStickyFeatureToggle = (editedEvent, field) => {
		this.props.handleStickyFeatureToggle(editedEvent, field);

		this.saveNeeded();
	}

	updateTags = (tags) => {
      this.setState({
          availableTags: tags
      });
  }

  updateStatusCounts = (statuses) => {
      this.setState({
          statuses
      });
  }

  updateStatuses = () => {
      let statuses = _.cloneDeep(this.state.statuses);
			let events = _.cloneDeep(this.state.activeEvents);

      statuses.map(status => {
        status.count = 0;
      })

      events.map(event => {
        if (event.status === 'producer-published' || event.status === 'unapproved' || event.status === 'auto-approved' || event.status === 'admin-approved') {
          statuses[1].count ++;
        } else if (event.status === 'approved' || event.status === 'published') {
          statuses[2].count ++;
        } else {
          statuses[3].count ++;
        }
      })

      statuses[0].count = events.length;

      this.setState({
          statuses: statuses
      });
  }

	saveNotNeeded = () => {
		this.setState({
			saveNeeded: false
		});
	}

	saveNeeded = () => {
		this.setState({
			saveNeeded: true
		});
	}

	save = () => {
		let events = _.cloneDeep(this.state.events);

		let touchedEvents = events.filter(event => {
			return event.status === 'approved' || event.status === 'rejected';
		})

		touchedEvents.map(event => {
			event.event_id = event.id;

			delete event.id;
			delete event.venue_id;
			delete event.name;
			delete event.districts;
			delete event.active;
			delete event.date_created;
			delete event.date_modified;
			delete event.exported;
			delete event.import_id;
			delete event.source_event_id;
			delete event.url;
			delete event.start_date;
			delete event.end_date;
			delete event.owner_client_id;
			delete event.address;
			delete event.city;
			delete event.producer;
			delete event.state;
			delete event.zip;
			delete event.source;
			delete event.startUnix;

			if (event.sticky == null) {
				event.sticky = 0;
			}

			if (event.featured == null) {
				event.featured = 0;
			}
		})

		this.putEvents(touchedEvents);
	}

	putEvents = async (events) => {
		const toastrConfig = {
			"preventDuplicates": false,
			"timeOut": "5000",
			"hideMethod": "fadeOut",
			"progressBar": false,
			"extendedTimeOut": "1000",
			"showEasing": "swing",
			"closeButton": true,
			"hideEasing": "linear",
			"positionClass": "toast-top-right",
			"newestOnTop": false,
			"showDuration": "300",
			"debug": false,
			"hideDuration": "1000",
			"showMethod": "fadeIn"
		};

		toastr.options = toastrConfig;

		try {
			if (this.state.saveNeeded) {
				const eventsResponse = await API.post('/events/extended', {
					events: events
				});

				if (eventsResponse.data.success) {
					toastr.success('Events saved sucessfully ...', 'Woohoo!');

					this.saveNotNeeded();
				} else {
					toastr.error(`${eventsResponse.data.message}`,'Darn, events not saved ...');
				}
			} else {
				toastr.warning('No changes to save ...', 'No worries ...');
			}
		} catch(error) {
			console.log(error);

			toastr.error(`${error}`, 'Whoops, events not saved ...');
		}
	}

  render() {
    const state = this.state;
		let events = this.state.events;
		let activeEvents = this.state.activeEvents;
		let filteredEvents = this.state.filteredEvents;
    const filterEvents = this.filterEvents;
    const {contextRef} = this.state;
		const { accordionActiveIndex } = this.state;
		let producers = this.state.producers;
		let tags = this.state.tags;
		let districts = this.state.districts;
		let venues = this.state.venues;
		let activeProducers = this.state.activeProducers;
		let activeTags = this.state.activeTags;
		let activeDistricts = this.state.activeDistricts;
		let activeVenues = this.state.activeVenues;
		let producerFilters = this.state.producerFilters;
		let tagFilters = this.state.tagFilters;
		let districtFilters = this.state.districtFilters;
		let venueFilters = this.state.venueFilters;

		events.map(event => {
			if (producers.indexOf(event.producer) === -1) {
				producers.push(event.producer);
			}

			if (event.tags.length) {
				event.tags.map(tag => {
					if (tags.indexOf(tag) === -1 && tag != null && tag != '') {
						tags.push(tag);
					}
				})
			}

			if (event.districts.length) {
				event.districts.map(district => {
					if (districts.indexOf(district) === -1) {
						districts.push(district);
					}
				})
			}

			if (venues.indexOf(event.name) === -1 && event.name != null && event.name != '') {
				venues.push(event.name);
			}
		})

		let producerChecks = producers.map((producer, index) => {
			return (
				<Checkbox checked={producerFilters.length > 0 && producerFilters.includes(producer) ? true : false} key={index} style={{display:'block'}} className='producerCheckbox' label={producer} onChange={this.handleProducerCheckFilterChange}/>
			);
		})

		let tagChecks = tags.map((tag, index) => {
			return (
				<Checkbox checked={tagFilters.length > 0 && tagFilters.includes(tag) ? true : false} key={index} style={{display:'block'}} className='tagCheckbox' label={tag} onChange={this.handleTagCheckFilterChange}/>
			);
		})

		let districtChecks = districts.map((district, index) => {
			return (
				<Checkbox checked={districtFilters.length > 0 && districtFilters.includes(district) ? true : false} key={index} style={{display:'block'}} className='districtCheckbox' label={district} onChange={this.handleDistrictCheckFilterChange}/>
			);
		})

		let venueChecks = venues.map((venue, index) => {
			return (
				<Checkbox checked={venueFilters.length > 0 && venueFilters.includes(venue) ? true : false} key={index} style={{display:'block'}} className='venueCheckbox' label={venue} onChange={this.handleVenueCheckFilterChange}/>
			);
		})

    let eventDivs = filteredEvents.filter(filteredEvent => {return activeEvents.some(activeEvent => {return filteredEvent.id === activeEvent.id})}).map((event, index) => {
        return (
            <EventItem
              key={event.id}
              event={event}
              statusChange={this.filterEvents}
              availableTags={state.availableTags}
              toggleSelected={this.toggleEventSelected}
              selected={state.selected}
							handleStickyFeatureToggle={this.handleStickyFeatureToggle}
							saveNeeded={this.saveNeeded}
            />
        );
    });

    let chunked = _.chunk(eventDivs, 50);

    let rendered = chunked.slice(0, this.state.page);

    rendered[rendered.length - 1] = (
			<Visibility	onUpdate={this.scrollUpdate} >
				{rendered[rendered.length - 1]}
			</Visibility>
		);

		let sensor = (
			<VisibilitySensor
				scrollCheck={true}
				scrollDelay={20}
				intervalCheck={false}
				partialVisibility={true}
				containment={document.getElementById('results')}
				onChange={(isVisible) => {
					let msg = isVisible ? 'visible!' : 'hidden';
	        if (isVisible && this.state.page < chunked.length) {
	          this.setState({
	            page: this.state.page + 1
	          });
	        }
				}}
			>
				<div className='sensor' />
			</VisibilitySensor>
		);

    rendered.push(sensor);

    let list = (
      <Visibility id='visibility' onUpdate={this.scrollUpdate} context={contextRef}>
        <Segment.Group id='event-list-container'>
					{rendered}
				</Segment.Group>
      </Visibility>
    );

    return (
			<Sidebar.Pushable style={{transform: 'none'}}>
				<Sidebar
					animation='overlay'
					onHide={this.handleSidebarVis}
					vertical='true'
					visible={!this.state.sidebarHidden}
					className='filterPopout'
					width='wide'
					direction='right'
				>
					<Button inverted color='red' icon labelPosition='left' onClick={this.clearFilters}>
						Clear Filters
						<Icon name='ban' />
					</Button>
					<Accordion>
						<Accordion.Title active={accordionActiveIndex === 0} index={0} onClick={this.handleAccordion}>
							<Icon name='dropdown' />
							Producers
						</Accordion.Title>
						<Accordion.Content active={accordionActiveIndex === 0}>
							{producerChecks}
						</Accordion.Content>
						<Accordion.Title active={accordionActiveIndex === 1} index={1} onClick={this.handleAccordion}>
							<Icon name='dropdown' />
							Tags
						</Accordion.Title>
						<Accordion.Content active={accordionActiveIndex === 1}>
							{tagChecks}
						</Accordion.Content>
						<Accordion.Title active={accordionActiveIndex === 2} index={2} onClick={this.handleAccordion}>
							<Icon name='dropdown' />
							Districts
						</Accordion.Title>
						<Accordion.Content active={accordionActiveIndex === 2}>
							{districtChecks}
						</Accordion.Content>
						<Accordion.Title active={accordionActiveIndex === 3} index={3} onClick={this.handleAccordion}>
							<Icon name='dropdown' />
							Venues
						</Accordion.Title>
						<Accordion.Content active={accordionActiveIndex === 3}>
							{venueChecks}
						</Accordion.Content>
					</Accordion>
				</Sidebar>
				<Sidebar.Pusher dimmed={!this.state.sidebarHidden}>
					<div className='EventList' id='event-list'>
	            <Grid className='EventList'>
									<Grid.Column>
											<div className='buttonsRow'>
												<Grid.Row centered className='saveButtonRow'>
					                <Grid.Column width={4} floated='left'>
														<Button icon labelPosition='right' onClick={this.handleSidebarVis}>
					            				Filter
															<Icon name='filter' />
					          				</Button>
													</Grid.Column>
													<Grid.Column width={8} className='statusFilterColumn'>
														<Button.Group className='filter-controls'>
						                  <Button active={this.state.filter === 'all'} onClick={(e) => this.handleFilterChange(e)}>All ({this.state.statuses[0].count})</Button>
						                  <Button.Or />
						                  <Button active={this.state.filter === 'not reviewed'} onClick={(e) => this.handleFilterChange(e)}>Not Reviewed ({this.state.statuses[1].count})</Button>
						                  <Button.Or />
						                  <Button active={this.state.filter === 'approved'} onClick={(e) => this.handleFilterChange(e)}>Approved ({this.state.statuses[2].count})</Button>
						                  <Button.Or />
						                  <Button active={this.state.filter === 'rejected'} onClick={(e) => this.handleFilterChange(e)}>Rejected ({this.state.statuses[3].count})</Button>
						                </Button.Group>
													</Grid.Column>
													<Grid.Column width={4} floated='right' className='saveButtonColumn'>
														{this.state.saveNeeded ? <Label pointing='right' color='yellow'>Make Sure to Save your Events!</Label> : null}
														<Button id='saveButton' color='pink' inverted icon labelPosition='left' onClick={this.save}><Icon name='save'/>Save</Button>
													</Grid.Column>
												</Grid.Row>
					              <Grid.Row centered columns={2}>
					                <Grid.Column floated='left'>
					                  <Button color='green' inverted onClick={this.approveAll}>
					                    Approve All
					                  </Button>
					                  <Button color='red' inverted onClick={this.rejectAll}>
					                    Reject All
					                  </Button>
					                </Grid.Column>
					                <Grid.Column floated='right'>
					                  <Button floated='right' color='green' inverted onClick={this.approveSelected}>
					                    Approve Selected
					                  </Button>
					                  <Button floated='right' color='red' inverted onClick={this.rejectSelected}>
					                    Reject Selected
					                  </Button>
					                </Grid.Column>
					              </Grid.Row>
											</div>
										<Grid.Row className='event-list-container'>
											{list}
										</Grid.Row>
									</Grid.Column>
	            </Grid>
	        </div>
				</Sidebar.Pusher>
			</Sidebar.Pushable>
    );
  }
}

class EventItem extends Component {
  state = {
    event: this.props.event,
    tags: this.props.event.tags || [],
    selected: []
  };

  componentWillReceiveProps = (nextProps) => {
      if (!_.isEqual(nextProps.event, this.state.event)) {
          this.setState({
              event: nextProps.event,
              tags: nextProps.event.tags || []
          });
      }
  }

  removeTag = (tag) => {
      const event = this.state.event;
      const tags = this.state.tags;

      let index = tags.indexOf(tag);

      if (index > -1) {
          tags.splice(index, 1);
          event.tags = tags;
					event.status = 'approved';
          this.setState({
              event: event
          }, this.props.saveNeeded);
      }
  }

  addTag = (tag) => {
      const event = this.state.event;
      const tags = this.state.tags;
      let index = tags.indexOf(tag);

      if (index <= -1 && tag) {
          tags.push(tag);
          event.tags = tags;
					event.status = 'approved';
          this.setState({
              event: event
          }, this.props.saveNeeded);
      }
  }

  editEvent = (property, value) => {
      const event = this.state.event;
      event[property] = value;
			event.status = 'approved';

      this.setState({
          event: event
      }, this.props.saveNeeded);

      // api.output('eventEdited', event);
  }

  handleCheckboxClick = (eventData, e, data) => {
      this.props.toggleSelected(eventData, e, data);
  }

	handleStickyToggleChange = () => {
		const event = _.cloneDeep(this.state.event);

		this.props.handleStickyFeatureToggle(event, 'sticky')
	}

	handleFeaturedToggleChange = () => {
		let event = _.cloneDeep(this.state.event);

		this.props.handleStickyFeatureToggle(event, 'featured')
	}

  render() {
      const event = this.state.event;
      const tags = this.state.event.tags;
      const selected = this.props.selected;
      let labelColor;

      switch (event.status) {
          case 'rejected':
          case 'admin-rejected':
          case 'auto-rejected':
          case 'delete-pending':
              labelColor = 'red';
              break;
          case 'approved':
					case 'published':
              labelColor = 'green';
              break;
          default:
              labelColor = 'orange';
      }

			let sticky = (
				<div className='stickyToggle'>
					<Label color={event.sticky === 1 ? 'green' : 'orange'}>Sticky: </Label>
					<Checkbox toggle checked={event.sticky === 1} onChange={this.handleStickyToggleChange} />
				</div>
			);

			let featured = (
				<div className='featuredToggle'>
					<Label color={event.featured === 1 ? 'green' : 'orange'}>Featured: </Label>
					<Checkbox toggle checked={event.featured === 1} onChange={this.handleFeaturedToggleChange} />
				</div>
			);

			let districtsLabels = (
				event.districts.map((district, index) => {
					return <Label key={index} color='teal' horizontal>{district}</Label>
				})
			);

			let districtsList = (
				<div className='districtsList'>
					<Label>Districts: </Label>
					{districtsLabels}
				</div>
			);
      return (
				<Segment className='EventItem'>
					<Grid divided='vertically'>
						<Grid.Row columns={2} divided>
							<Grid.Column className='eventItemLeftColumn' width={11}>
								<div className='event-info'>
									<div className='eventDetailsRow'>
										<Title title={event.title} url={event.url} url_logo={event.urlLogo} editTitle={this.editEvent} />
									</div>
									<div className='eventDetailsRow venuesRow'>
										<Label>Venue: </Label>
										<h5>{event.name}</h5>
									</div>
									<div className='eventDetailsRow'>
										{districtsList}
									</div>
									<div className='eventDetailsRow'>
										<DateInfo start={event.start_date} end={event.end_date} />
									</div>
									<div className='eventDetailsRow'>
										<TagList tags={tags} removeTag={this.removeTag} addTag={this.addTag} availableTags={this.props.availableTags} />
									</div>
									<div className='eventDetailsRow'>
										{sticky}
									</div>
									<div className='eventDetailsRow'>
										{featured}
									</div>
								</div>
							</Grid.Column>
							<Grid.Column className='eventItemRightColumn' floated='right' width={5}>
								<Grid.Row>
									<Grid.Column floated='right'>
										<div className='event-checkbox'>
											<Label size='medium' color={labelColor}>
												{event.status === 'unapproved' || event.status === 'admin-approved' || event.status === 'auto-approved'? 'PENDING REVIEW' : event.status.toUpperCase()}
											</Label>
											<Checkbox checked={_.findIndex(selected, (e) => e.id === event.id) > -1} onClick={this.handleCheckboxClick.bind(this, (event))} />
										</div>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column floated='right'>
										<div className='curate-event-img'>
											<Image src={event.urlLogo} />
										</div>
									</Grid.Column>
								</Grid.Row>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<div className='descriptionContainer'>
								<Description text={event.description} editDescription={this.editEvent} />
							</div>
						</Grid.Row>
					</Grid>
				</Segment>
      );
  }
}

function Title(props) {
	return (
		<div>
			<EditableField long={false} value={props.title} name='Title' tag='h1' onFieldEdit={value => props.editTitle('title', value)} />
			<div className='event-image-url-container'>
				<Label>Image: </Label>
				<EditableField long={false} value={props.url_logo} name='url_logo' tag='p' onFieldEdit={value => props.editTitle('url_logo', value)} />
			</div>
			<div className='event-url-container'>
				<Label>Link: </Label>
				<p>{props.url}</p>
			</div>
		</div>
	);
}

class DateInfo extends Component {
	state = {
		start: this.props.start,
		end: this.props.end
	};

	render() {
		return (
      <div className='datetimeContainer'>
				<Label>Start: </Label>
				<DateTimePicker disabled value={this.state.start == null || this.state.start === '' ? null : new Date(this.state.start)}  className='startDateTime' />
				<Label>End: </Label>
				<DateTimePicker disabled value={this.state.end == null || this.state.end === '' ? null : new Date(this.state.end)}  className='endDateTime' />
			</div>
    );
	}
}

class TagList extends Component {
	state = {
		addingTag: false
	};

	componentDidUpdate = () => {
		//in theory this is where we maybe focus on the input? Not sure.
		// Have to use "open" instead of "focus" since this is actually a third-party component from Semantic UI. :\
		if (this.textInput) {
			this.textInput.open();
		}
	}

	removeTag = (tag) => {
			this.props.removeTag(tag);
	}

	addTag = (e, data) => {
			let tag = data.value;

			this.setState({
					addingTag: false
			});

			if (tag !== 'select-tag')
				this.props.addTag(tag);
	}

	showSelectList = () => {
			this.setState({
					addingTag: true
			});
	}

	handleKeyDown = (event) => {
			if (event.keyCode === 27) {
					this.setState({
							addingTag: false
					});
			}
	}

	handleRef = (input) => {
		this.textInput = input;
	}

	render() {
			let tags = this.props.tags || [];

			tags = tags.sort();

			let tagDivs = tags.map((tag, index) => {
					return (
							<Tag key={index} text={tag} onTagClick={this.removeTag} />
					);
			});

			let addTagButton;

			if (!this.state.addingTag) {
				addTagButton = <Label as='a' color='green' basic size='small'  onClick={this.showSelectList}><Icon name='plus' />Add</Label>;
			}

			let tagOpts = [];
			this.props.availableTags.map((tag, index) => {
				tagOpts.push(
					{key:index+1, value:tag.tag, text:tag.tag}
				);
			})

			let tagSelectList;

			if (this.state.addingTag) {
				tagSelectList = <Dropdown placeholder='Select Tag' onChange={this.addTag} onAddItem={this.addTag} search={true} selection={true} allowAdditions={true} additionpostion='bottom' options={tagOpts} onKeyDown={this.handleKeyDown} ref={this.handleRef}/>;
			}

			let tagsLabel = (
				<Label>Tags:</Label>
			);

			return (
					<div className='Taglist'>
						{tagsLabel}
						{tagDivs}
						{addTagButton}
						{tagSelectList}
					</div>
			);
	}
}

class Tag extends Component {
	handleClick = (e) => {
			this.props.onTagClick(this.props.text);
	}

	render() {
			return (
				<Label as='a' color='pink' onClick={this.handleClick}>
					{this.props.text}
					<Icon name='delete' />
				</Label>
			);
	}
}

class Description extends Component {
	setDescription = (value) => {
			this.props.editDescription('description', value);
	}

	render() {
			return (
				<EditableField long={true} value={this.props.text} name='Description' tag='p' onFieldEdit={this.setDescription} />
			);
	}
}

class EditableField extends Component {
	state = {
		editing: false,
		value: this.props.value,
		originalValue: this.props.value
	};

	componentWillReceiveProps = (nextProps) => {
			this.setState({
					editing: false,
					value: nextProps.value,
					originalValue: nextProps.value
			});
	}

	componentDidUpdate = (prevProps, prevState) => {
			let ref = this[this.props.name];
			if (ref) {
					ref.focus();
			}
	}

	editField = () => {
			this.setState({
					editing: true
			});
	}

	handleChange = (event) => {
			this.setState({
					value: event.target.value
			});
	}

	handleSubmit = (event) => {
			event.preventDefault();

			let value = this.state.value.trim();

			if (value) {
					this.setState({
							editing: false,
							value: value,
							originalValue: value
					});

					this.props.onFieldEdit(value);
			}
	}

	handleCancel = (event) => {
			event.preventDefault();

			this.setState({
					editing: false,
					value: this.state.originalValue
			});
	}

	handleKeyPress = (event) => {
			if (event.key === 'Enter' && event.nativeEvent.shiftKey) {
					if (event.target.value.trim()) {
							this.handleSubmit(event);
					}
			}
	}

	handleKeyDown = (event) => {
			if (event.keyCode === 27) {
				this.handleCancel(event);
			}
	}

	getHighlightedText = (text, highlight) => {

			if (highlight) {
				let parts = text.split(new RegExp(`(${ highlight })`, 'gi'));

				return React.createElement('span', {}, parts.map((part, i) => {
						return React.createElement('span', {key: i, className: part.toLowerCase() === highlight.toLowerCase() ? 'text-highlight' : ''}, part);
				}));
			} else {
				return text;
			}
	}

	render() {
			let DynInput = this.props.long ? 'textarea' : 'input';
			let help = <p>Shift + Enter to Submit</p>;
			let Element = this.props.tag;

			let value = '';

			let input = (
				<DynInput className={DynInput === 'textarea' ? 'edit-textarea' : 'edit-input'} value={this.state.value} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onKeyDown={this.handleKeyDown} ref={(input) => this[this.props.name] = input} />
			);

			let field;

			if (this.state.editing) {
				field = (
					<form className='edit-form' onSubmit={this.handleSubmit}>
						{input}
						{DynInput === 'textarea' ? help : null}
					</form>
				);
			} else {
				if (this.state.value) {
					field = (
						<Element onClick={this.editField} className={this.props.name}>
							{this.getHighlightedText(this.state.value, this.props.searchTerm)}
						</Element>
					);
				} else {
					console.log('wut', this.props.name, this.state)
					field = (
						<button onClick={this.editField} className={`${this.props.name} missing-data-btn`}>
							Missing ${this.props.name}
						</button>
					);
				}
			}

			return field;
	}
}

function formatDate(eventDate) {
	let newDate;

	if (eventDate && eventDate != null && eventDate != ''){
		if (eventDate.indexOf("Z") > -1) {
			newDate = eventDate.substr(0,19);
			newDate = newDate.replace("T", ' ');

			return newDate;
		} else {
			return eventDate;
		}
	} else {
		return null
	}

}

function buildDateTimeString(dt) {
	function pad(n){return n<10 ? '0'+n.toString() : n.toString();}

  return dt.getFullYear()+'-'+pad(dt.getMonth()+1)+'-'+pad(dt.getDate())+' '+pad(dt.getHours())+':'+pad(dt.getMinutes())+':00';
}
