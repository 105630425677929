import React, { Component } from 'react';
import API from '../../services/API';
import toastr from 'toastr';
import {
	Segment,
	Label,
	Button,
	Image,
	Grid,
	Checkbox,
	Icon,
} from 'semantic-ui-react';
import './promoterPublishStyles.css';
import _ from 'lodash';
import wordpressExport from './../../services/wordpressExport.js';
import iconLogo from '../../assets/Icon-Blue.jpg';

export default class PromoterPublish extends Component {
	state = {
		items: [],
		filter: 'all',
		saveNeeded: false,
		client: {},
		loading: true,
	};

	async componentDidMount() {
		try {
			const clientResponse = await API.get('/clients');

			const eventsResponse = await API.get('/events/publish');

			let statuses = [];

			eventsResponse.data.content.map(event => {
				if (event.tags != null && event.tags != '') {
					event.tags = event.tags.split(',');
				} else {
					event.tags = [];
				}

				if (event.districts != null && event.districts != '') {
					event.districts = event.districts.split(',');
				} else {
					event.districts = [];
				}

				if (event.status === 'published') {
					event['active'] = true;
				} else {
					event['active'] = false;
				}

				statuses.push(event.status);
			});

			this.setState({
				items: eventsResponse.data.content,
				client: clientResponse.data.content,
				loading: false,
			});
		} catch (error) {
			console.log(error);
		}
	}

	handleToggleChange = item => {
		let items = _.cloneDeep(this.state.items);
		let index = _.findIndex(items, i => i.event_id === item.event_id);

		if (index > -1) {
			items[index].active = !items[index].active;
			items[index].status =
				items[index].active == true ? 'published' : 'approved';
		}

		this.setState({
			items: items,
			saveNeeded: true,
		});
	};

	handleFilterChange = value => {
		let filter = value.target.innerHTML.substr(0, 2);

		let filterValue = filter === 'Al' ? 'all' : filter === 'Of' ? 'off' : 'on';

		this.setState({
			filter: filterValue,
		});
	};

	toggleAll = e => {
		let items = _.cloneDeep(this.state.items);

		let activeStatus = e.target.innerHTML.indexOf('On') >= 0 ? true : false;

		let itemStatus = activeStatus == true ? 'published' : 'approved';

		items.map(item => {
			item.active = activeStatus;
			item.status = itemStatus;
		});

		this.setState({
			items: items,
			saveNeeded: true,
		});
	};

	saveNotNeeded = () => {
		this.setState({
			saveNeeded: false,
		});
	};

	save = () => {
		this.setState({
				loading: true
		});

		let events = _.cloneDeep(this.state.items);

		events.map(event => {
			delete event.venue_id;
			delete event.active;
			delete event.date_created;
			delete event.date_modified;
			delete event.exported;
			delete event.import_id;
			delete event.source_event_id;
			delete event.owner_client_id;

			if (event.sticky == null) {
				event.sticky = 0;
			}

			if (event.featured == null) {
				event.featured = 0;
			}
		});

		this.putEvents(events);
	};

	putEvents = async events => {
		const toastrConfig = {
			preventDuplicates: false,
			timeOut: '5000',
			hideMethod: 'fadeOut',
			progressBar: false,
			extendedTimeOut: '1000',
			showEasing: 'swing',
			closeButton: true,
			hideEasing: 'linear',
			positionClass: 'toast-top-right',
			newestOnTop: false,
			showDuration: '300',
			debug: false,
			hideDuration: '1000',
			showMethod: 'fadeIn',
		};

		const client = this.state.client;

		toastr.options = toastrConfig;

		try {
			if (this.state.saveNeeded) {
				const eventsResponse = await API.post('/events/extended', {
					events: events,
				});

				if (eventsResponse.data.success) {
					if (client.api_export_on && !!client.api_export_on && client.website_cms === 'wordpress' ) {
						const wordpressExportResponse = await wordpressExport(events, client.export_baseurl, !!client.export_districts_as_tags);
					}

					this.setState({
							loading: false
					});

					toastr.success('Events saved sucessfully ...', 'Woohoo!');

					this.saveNotNeeded();
				} else {
					toastr.error(
						`${eventsResponse.data.message}`,
						'Darn, events not saved ...'
					);
				}
			} else {
				toastr.warning('No changes to save ...', 'No worries ...');
			}
		} catch (error) {
			console.log(error);

			toastr.error(`${error}`, 'Whoops, events not saved ...');
		}
	};

	render() {

		let items = this.state.items
			.filter(item => {
				if (this.state.filter === 'on') {
					return item.active;
				} else if (this.state.filter === 'off') {
					return !item.active;
				} else {
					return true;
				}
			})
			.map((item, index) => {
				const tags = item.tags.map((tag, index) => {
					return (
						<Label horizontal color="pink">
							{tag}
						</Label>
					);
				});

				const districts = item.districts.map(district => {
					return (
						<Label color="teal" horizontal>
							{district}
						</Label>
					);
				});

				let header = item.url ? (
					<a href={item.url} target="_blank">
						{item.title}
					</a>
				) : (
					<div className="header">{item.title}</div>
				);

				let sticky =
					item.sticky && item.sticky === 1 ? (
						<Label horizontal color="blue" className="stickyLabel">
							sticky
						</Label>
					) : (
						<div className="stickyLabel" />
					);

				let featured =
					item.featured && item.featured === 1 ? (
						<Label horizontal color="blue" className="featuredLabel">
							featured
						</Label>
					) : (
						<div className="featuredLabel" />
					);

				return (
					<div className="itemContainer">
						<Segment className="item" key={item.id}>
							<div className="image">
								<Image src={item.url_logo} alt={item.title} size="small" />
							</div>
							<div className="content">
								{header}
								<div className="toggleContainer">
									{item.active ? (
										<Label horizontal color="green">
											On
										</Label>
									) : (
										<Label horizontal color="red">
											Off
										</Label>
									)}
									<Checkbox
										className="toggler"
										toggle
										checked={item.active}
										onChange={this.handleToggleChange.bind(this, item)}
									/>
								</div>
								{sticky}
								{featured}
								<div className="meta">
									{item.name}: {formatDate(item.start_date)}{' '}
									{item.end_date == null ? '' : 'to'}{' '}
									{formatDate(item.end_date)}
								</div>
								<div className="meta metadata">
									<Label>Districts:</Label>
									{districts}
								</div>
								<div className="extra">
									<Label>Tags: </Label>
									{tags}
								</div>
							</div>
							<div className="description">
								<p>{item.description}</p>
							</div>
						</Segment>
					</div>
				);
			});

		const itemCount = this.state.items.length;
		const toggleOnCount = this.state.items.filter(item => item.active).length;
		const toggleOffCount = itemCount - toggleOnCount;

		let noEventsAlert = (
			<Button disabled color="orange" fluid>
				Whoops ... There are no events to publish. Go to the curate page and
				approve events to see them here.
			</Button>
		);

		let out = (
			<div className="container">
				<Grid>
					<Grid.Row centered>
						<Button.Group className="filterButtons">
							<Button
								active={this.state.filter === 'all'}
								onClick={e => this.handleFilterChange(e)}
							>
								All ({itemCount})
							</Button>
							<Button.Or />
							<Button
								active={this.state.filter === 'on'}
								onClick={e => this.handleFilterChange(e)}
							>
								On ({toggleOnCount})
							</Button>
							<Button.Or />
							<Button
								active={this.state.filter === 'off'}
								onClick={e => this.handleFilterChange(e)}
							>
								Off ({toggleOffCount})
							</Button>
						</Button.Group>
					</Grid.Row>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Button
								color="green"
								inverted
								floated="left"
								onClick={e => this.toggleAll(e)}
							>
								Toggle All On
							</Button>
							<Button color="red" inverted onClick={e => this.toggleAll(e)}>
								Toggle All Off
							</Button>
						</Grid.Column>
						<Grid.Column floated="right">
							<div className="saveButtonAndLabel">
								{this.state.saveNeeded ? (
									<Label pointing="right" color="yellow">
										Make Sure to Save your Changes!
									</Label>
								) : null}
								<Button
									icon
									color="pink"
									floated="right"
									inverted
									className="saveToDB shipToWP"
									onClick={this.save}
								>
									<Icon name="save" />
									Save Changes
								</Button>
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>{this.state.items.length ? items : noEventsAlert}</Grid.Row>
				</Grid>
			</div>
		);

		return (
			this.state.loading ?
				<Image src={iconLogo} size='tiny' className='App-logo'/>
			:
				out
		);
	}
}

function formatDate(eventDate) {
	let newDate;

	if (eventDate != null && eventDate != '') {
		if (eventDate.indexOf('Z') > -1) {
			newDate = eventDate.substr(0, 19);
			newDate = newDate.replace('T', ' ');

			return newDate;
		} else {
			return eventDate;
		}
	} else {
		return null;
	}
}
