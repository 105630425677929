import { includes } from 'lodash';
import { Container } from 'unstated';

import { STATE_FULFILLED, STATE_PENDING, STATE_PRE, STATE_REJECTED } from '../../constants/asyncConstants';
import { EXPIRE_TIME, EXPIRES_ERROR, EXPIRES_IN, EXPIRES_PENDING } from '../../constants/storeConstants';
import { Deferred } from '../../utils/deferred';
import { getCase } from '../../utils/getCase';

/**
 * ApiBaseStore
 */
export class ApiBaseStore extends Container {
	/**
	 * The state of the container.
	 *
	 * @type {{data: ?*, status: symbol, error: ?(Error|*)}}
	 */
	state = {
		status: STATE_PRE,
		data: null,
		error: null,
	};

	/**
	 * The store creation id.
	 *
	 * @type {?string}
	 */
	id = null;

	/**
	 * A deferred promise.
	 *
	 * @type {?Deferred}
	 */
	defer = null;

	/**
	 * @constructor
	 * @param {string} id
	 */
	constructor(id) {
		super();

		this.id = String(id);
		this.subscribe(this.updateDeferOnStateUpdate);
	}

	/**
	 * Updates the deferred promise when the store state updates.
	 */
	updateDeferOnStateUpdate = () => {
		const currentStatus = this.getStatus();

		if (this.defer && !this.defer.isFulfilled) {
			if (currentStatus === STATE_REJECTED) {
				this.defer.reject(this.getRejected());
			} else if (currentStatus === STATE_FULFILLED) {
				this.defer.resolve(this.getFulfilled());
			}
		}
	};

	/**
	 * Makes the specified server request.
	 *  This should be overwritten in every class that extends this.
	 */
	request() {
		const notOverwrittenError = new Error(
			`The request function was not overwritten in the ${this.constructor.name} class declaration.`
		);

		this.setState({
			status: STATE_REJECTED,
			[EXPIRE_TIME]: null,
			data: null,
			error: notOverwrittenError,
		});

		throw notOverwrittenError;
	};

	/**
	 * Gets the fulfilled value of the store.
	 * This is used in case().
	 *
	 * @returns {?{}}
	 */
	getFulfilled() {
		return this.state.data;
	};

	/**
	 * Gets the rejected value of the store.
	 * This is used in case().
	 *
	 * @returns {?Error}
	 */
	getRejected() {
		return this.state.error;
	};

	/**
	 * Gets the status of the store.
	 *
	 * @returns {symbol}
	 */
	getStatus() {
		return this.state.status;
	};

	/**
	 * Gets a promise for this store.
	 *
	 * @returns {Promise}
	 */
	getPromise() {
		if (this.getIsRejected()) {
			return Promise.reject(this.getRejected());
		} else if (this.getIsFulfilled()) {
			return Promise.resolve(this.getFulfilled());
		}

		if (!this.defer) {
			this.defer = new Deferred();
		}

		return this.defer.promise;
	};

	/**
	 * Gets whether or not the store is in the pre state.
	 *
	 * @returns {boolean}
	 */
	getIsPre() {
		return (this.getStatus() === STATE_PRE);
	}

	/**
	 * Gets whether or not the store is in the pending state.
	 *
	 * @param {boolean=} includePre
	 * @returns {boolean}
	 */
	getIsPending(includePre) {
		if (includePre && this.getIsPre()) {
			return true;
		}

		return (this.getStatus() === STATE_PENDING);
	}

	/**
	 * Gets whether or not the store is in the rejected state.
	 *
	 * @returns {boolean}
	 */
	getIsRejected() {
		return (this.getStatus() === STATE_REJECTED);
	}

	/**
	 * Gets whether or not the store is in the fulfilled state.
	 *
	 * @returns {boolean}
	 */
	getIsFulfilled() {
		return (this.getStatus() === STATE_FULFILLED);
	}

	/**
	 * Sets the store to a pre state.
	 *
	 * @returns {Promise}
	 */
	setPre() {
		return this.setState({
			status: STATE_PRE,
			data: null,
			error: null,
			[EXPIRE_TIME]: undefined,
		});
	};

	/**
	 * Sets the store to a pending state.
	 *
	 * @param {(number|boolean)=} expireTime Must be false to give no expire time.
	 * @returns {Promise}
	 */
	setPending(expireTime) {
		let safeExpireTime = expireTime;
		if (expireTime === false) {
			safeExpireTime = undefined;
		} else if (!expireTime) {
			safeExpireTime = Date.now() + EXPIRES_PENDING;
		}

		return this.setState({
			status: STATE_PENDING,
			data: null,
			error: null,
			[EXPIRE_TIME]: safeExpireTime,
		});
	};

	/**
	 * Sets the store to a fulfilled state.
	 *
	 * @param {*} data
	 * @param {(number|boolean)=} expireTime Must be false to give no expire time.
	 * @returns {Promise}
	 */
	setFulfilled(data, expireTime) {
		let safeExpireTime = expireTime;
		if (expireTime === false) {
			safeExpireTime = undefined;
		} else if (!expireTime) {
			safeExpireTime = Date.now() + EXPIRES_IN;
		}

		return this.setState({
			status: STATE_FULFILLED,
			data,
			error: null,
			[EXPIRE_TIME]: safeExpireTime,
		});
	};

	/**
	 * Sets the store to a rejected state.
	 *
	 * @param {Error|*} error
	 * @param {(number|boolean)=} expireTime Must be false to give no expire time.
	 * @returns {Promise}
	 */
	setRejected(error, expireTime) {
		let safeExpireTime = expireTime;
		if (expireTime === false) {
			safeExpireTime = undefined;
		} else if (!expireTime) {
			safeExpireTime = Date.now() + EXPIRES_ERROR;
		}

		return this.setState({
			status: STATE_REJECTED,
			data: null,
			error,
			[EXPIRE_TIME]: safeExpireTime,
		});
	}

	/**
	 * Runs handlers based on changes in the state.
	 *
	 * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
	 * @returns {{}}
	 */
	case(handlers) {
		const getFulfilled = () => this.getFulfilled();
		const getRejected = () => this.getRejected();

		return getCase(this.getStatus(), getFulfilled, getRejected, handlers);
	}

	/**
	 * Checks if item is available in the store.
	 * Returns true if state is pending or fulfilled and not expired.
	 *
	 * @returns {boolean}
	 */
	isDataAvailable() {
		const isValidState = includes([STATE_PENDING, STATE_FULFILLED], this.getStatus());
		const isNotExpired = (this[EXPIRE_TIME] > Date.now());

		return (isValidState && isNotExpired);
	}
}
