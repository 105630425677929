/**
 * The id for getting a singleton of a store.
 *
 * @type {symbol}
 */
const SINGLETON_ID = Symbol('store-singleton');

/**
 * StoreCollection
 */
export class StoreCollection {
	/**
	 * The list of stores by id.
	 *
	 * @type {Object<string, Container>}
	 */
	stores = {};

	/**
	 * The class used to create a new store.
	 *
	 * @type {?Container}
	 */
	StoreClass = null;

	/**
	 * @constructor
	 * @param {Container} StoreClass
	 */
	constructor(StoreClass) {
		this.StoreClass = StoreClass;
	}

	/**
	 * Gets the Store for the given id or creates one.
	 *
	 * @param {string} id
	 * @returns {Container}
	 */
	get(id) {
		const safeId = (id === SINGLETON_ID) ? id : String(id);

		const currentContainer = this.stores[safeId];
		if (currentContainer) {
			return currentContainer;
		}

		if (!this.StoreClass) {
			throw new Error('Could not create store because no Store class was defined.');
		}

		const classForId = new this.StoreClass(safeId);

		this.stores[safeId] = classForId;

		return classForId;
	};

	/**
	 * Gets a single instance (singleton) of the store or creates one.
	 *
	 * @returns {Container}
	 */
	single() {
		return this.get(SINGLETON_ID);
	}

	/**
	 * Generates a random id.
	 *
	 * @returns {string}
	 */
	generateId() {
		return Math.random().toString(36).substring(2, 15)
			+ Math.random().toString(36).substring(2, 15);
	}
}
