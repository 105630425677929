import { STATE_FULFILLED, STATE_PENDING, STATE_PRE, STATE_REJECTED } from '../constants/asyncConstants';

/**
 * Gets the case statement for an api store.
 *
 * @param {Symbol} state
 * @param {function} getFulfilled
 * @param {function} getRejected
 * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
 * @returns {{}}
 */
export function getCase(state, getFulfilled, getRejected, handlers) {
	if (!handlers) {
		throw new Error('Invalid handlers given to case.');
	}

	switch (state) {
		case STATE_PENDING:
			return handlers.pending && handlers.pending();
		case STATE_FULFILLED:
			return handlers.fulfilled && handlers.fulfilled(getFulfilled());
		case STATE_REJECTED:
			return handlers.rejected && handlers.rejected(getRejected());
		case STATE_PRE:
		default:
			return handlers.pre && handlers.pre();
	}
}

export default getCase;
